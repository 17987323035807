<template>
  <div class="mcf-form-column mcf-completed">
    <div v-show="showAttributeName"
         class="mcf-label">
      {{ item.attribute.name }}
    </div>
    <div class="mcf-value">{{ value }}</div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, ref, toRef, unref} from "vue";
import EnumeratedItem from "@/models/patientData/EnumeratedItem";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object
});

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const value = ref('')
const showAttributeName = computed(() => !unref(item).attribute.list)

//methods
async function executePropertyValue() {
  if (!unref(property)?.value) {
    return Promise.resolve('-')
  }
  let enumeratedItem = new EnumeratedItem({id: unref(property).value})
  await enumeratedItem.fetch()
  return enumeratedItem.name
}

async function setPropertyValue() {
  let propertyValue = await executePropertyValue()
  if (unref(item).attribute.list) {
    value.value = `${unref(property).index + 1}. ${propertyValue}`
  } else {
    value.value = propertyValue
  }
}

//lifecycle
onMounted(() => {
  setPropertyValue()
})
</script>
