<template>
  <div v-if="isRender"
       class="mcf-form-column mcf-exception-fill mcf-datetime-wrapper"
       :class="setPropertiesClass">
    <label v-show="showAttributeName">{{ item.attribute.name }}:</label>
    <div class="mcf-input-datetime-wrapper">
      <div>
        <input type="text"
               class="mcf-input"
               :placeholder="$t('field.day')"
               v-model="form.day"
               @focusin="focusInDay"
               @focusout="focusOutDay"
               @input="updateValue"/>
        <input type="text"
               class="mcf-input"
               :placeholder="$t('field.month')"
               v-model="form.month"
               @focusin="focusInMonth"
               @focusout="focusOutMonth"
               @input="updateValue"/>
        <input type="text"
               class="mcf-input"
               :placeholder="$t('field.year')"
               v-model="form.year"
               @focusin="focusInYear"
               @focusout="focusOutYear"
               @input="updateValue"/>
      </div>
    </div>
    <div v-if="v$ && isShowErrorComputed"
         class="mcf-validation-errors-wrapper">
      <div v-for="($error, i) in v$.$errors"
           :key="i"
           class="mcf-validation-errors">
        <div class="mcf-validation-error">
          {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, reactive, ref, toRef, unref} from "vue";
import {DateFormatter} from "@/util/DateFormatter";
import {useVuelidate} from "@vuelidate/core";
import {isDay, isMonth, isYear} from "@/components/patientData/properties/validators/CustomValidators";
import {maxLength, minLength, numeric, required,} from '@vuelidate/validators'
import {useValidationErrors} from "@/composables/useValidationErrors";
import {ActiveClasses} from "@/components/patientData/ActiveClasses";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const autoFillData = toRef(props, 'autoFillData');
const isRender = ref(false)
const isFocusedOnYear = ref(false)
const isFocusedOnMonth = ref(false)
const isFocusedOnDay = ref(false)
const {getErrorMessage} = useValidationErrors()
const form = reactive({
  year: null,
  month: null,
  day: null
})
const rules = computed(() => ({
  form: {
    day: {
      numeric,
      maxLength: maxLength(2),
      isDate: isDay,
      ...(unref(item)?.attribute?.currentDisplaySetting?.required ? {required, minLength: minLength(1)} : {})
    },
    month: {
      numeric,
      maxLength: maxLength(2),
      isDate: isMonth,
      ...(unref(item)?.attribute?.currentDisplaySetting?.required ? {required, minLength: minLength(1)} : {})
    },
    year: {
      numeric,
      maxLengthValue: maxLength(4),
      isDate: isYear,
      ...(unref(item)?.attribute?.currentDisplaySetting?.required ? {required, minLength: minLength(4)} : {})
    }
  }
}));
const v$ = useVuelidate(rules, {form});
const showAttributeName = computed(() => !unref(item).attribute.list);
const isShowErrorComputed = computed(() => !(unref(isFocusedOnYear) || unref(isFocusedOnMonth) || unref(isFocusedOnDay)) && unref(item).isShowError);
const setPropertiesClass = computed(() => {
  let className = v$.value.form.$invalid && isShowErrorComputed.value ? ` ${ActiveClasses.ERROR_CLASS}` : '';
  if (form.day && form.month && form.year?.toString().length === 4) {
    className += ` ${ActiveClasses.FILL_CLASS}`;
  }
  return className;
})

//methods
const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value)
  }
}

function setFormValue() {
  item.value.validator = v$
  item.value.isReadyToModify = true

  if (unref(property)?.value != null) {
    Object.assign(form, setForm(new Date(unref(property).value)));
  } else if (unref(autoFillData)?.value != null) {
    setValue(new Date(unref(autoFillData).value))
  }

  isRender.value = true
}

function setValue(value) {
  Object.assign(form, setForm(value));
  property.value.value = value.getTime()
}

function updateValue() {
  if (form.year && form.month && form.day) {
    let updatedDate = new Date(Date.UTC(form.year, form.month - 1, form.day))

    property.value.value = updatedDate.getTime()
  } else {
    property.value.value = null
  }
}

function focusInYear() {
  isFocusedOnYear.value = true
}

function focusOutYear() {
  isFocusedOnYear.value = false
}

function focusInMonth() {
  isFocusedOnMonth.value = true
}

function focusOutMonth() {
  isFocusedOnMonth.value = false
}

function focusInDay() {
  isFocusedOnDay.value = true
}

function focusOutDay() {
  isFocusedOnDay.value = false
}

//lifecycle
onMounted(() => {
  setFormValue()
})
</script>
