<template>
  <div class="mcf-header-wrapper">
    <div class="mcf-container">
      <div class="mcf-header-position">
        <burger-link/>
        <router-link
          :to="localizeRouteLink('/')"
          id="mcf-header-logo"
          @click="onLinkClick">
          <img
            src="@/assets/images/logo/logo-white@2x.png"
            :class="[logoImgClass,'mcf-header-logo-img']"
            alt="Medicofolder Logo"
          />
        </router-link>
        <div class="mcf-header-option">
          <locale-selector/>
          <header-box/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, unref} from "vue";
import BurgerLink from "@/components/header/BurgerLink.vue";
import LocaleSelector from "@/components/header/LocaleSelector.vue";
import HeaderBox from "@/components/header/HeaderBox.vue";
import {useAppStore} from "@/stores/useAppStore";
import {useI18n} from "vue-i18n";
import UrlManager from "@/util/urlManager";

//state
const {locale} = useI18n()
const logoImgClass = computed(() => 'mcf-logo-img-' + unref(locale));
const store = useAppStore()

//methods
function localizeRouteLink(uri) {
  return UrlManager.composeLocaleUrl('', uri)
}
function onLinkClick() {
  document.body.classList.remove('mcf-active');
  store.setMobMenuStatus(false);
}
</script>

<style scoped lang="scss">
.mcf-header {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $header-background;
    z-index: 10;
    @include mq(tablet-wide, min) {
      position: relative;
      top: initial;
      left: initial;
      right: initial;
    }
  }

  &-position {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    height: $header-height;
    width: 100%;
    padding: 0;
    @include mq(tablet-wide, max) {
      padding: 0 16px;
      @include justify-content(flex-start);
    }
  }

  &-option {
    @include flexbox;
    @include align-items(center);
    @include mq(tablet-wide, max) {
      display: none;
    }
  }

  &-logo-img {
    display: inline-block;
    height: 32px;
    width: auto;
  }
}

</style>
