import {i18n} from "@/i18n";

export default class UrlManager {
  static findGetParameter(parameterName) {
    const getParams = location.search.substr(1).split('&')

    for (let i = 0; i < getParams.length; i++) {
      const paramNameAndValue = getParams[i].split('=') // [0] - name, [1] - value
      if (paramNameAndValue[0] === parameterName) {
        return decodeURIComponent(paramNameAndValue[1])
      }
    }
  }

  static removeParamFromUrl(paramName) {
    const currentUrl = window.location.href
    const newUrl = this.getUrlWithoutParameter(paramName, currentUrl)

    window.history.replaceState(null, document.title, newUrl)
  }

  static getUrlWithoutParameter(key, sourceURL) {
    // copied from https://stackoverflow.com/a/16941754/12046716
    let rtn = sourceURL.split('?')[0]
    const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : ''
    if (queryString !== '') {
      const paramsArr = queryString.split('&')
      for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
        const param = paramsArr[i].split('=')[0]
        if (param === key) {
          paramsArr.splice(i, 1)
        }
      }
      if (paramsArr.length) {
        rtn = rtn + '?' + paramsArr.join('&')
      }
    }
    return rtn
  }

  static composeLocaleUrl(domain, uri, params) {
    let queryParams = ''
    if (params) {
      queryParams = '?' + Object.keys(params).map((item) => `${item}=${params[item]}`).join('&')
    }
    const lang = i18n.global.locale.value

    if (lang === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
      return `${domain}${uri}${queryParams}`
    }
    if (!domain || (domain && !domain.endsWith('/'))) {
      domain = domain + '/'
    }
    if (uri && !uri.startsWith('/')) {
      uri = '/' + uri
    }

    return `${domain}${lang}${uri}${queryParams}`
  }
}
