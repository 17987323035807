import {useI18n} from "vue-i18n";

export function useValidationErrors() {
  const {t} = useI18n();

  function getErrorMessage(validatorName, fieldDisplayName, field, ruleParams) {
    switch (validatorName) {
      case 'required':
        return t('form.error.required', [fieldDisplayName, field !== 'value' ? field : '']);
      case 'decimal':
        return t('form.error.numeric', [fieldDisplayName, field]);
      case 'minLength':
        return t('form.error.minLength', [fieldDisplayName, field, ruleParams.minLength.$params.min]);
      case 'minLengthValue':
        return t('form.error.minLength', [fieldDisplayName, field, ruleParams.minLengthValue.$params.min]);
      case 'maxLength':
        return t('form.error.maxLength', [fieldDisplayName, field, ruleParams.maxLength.$params.max]);
      case 'maxLengthValue':
        return t('fo  rm.error.maxLength', [fieldDisplayName, field, ruleParams.maxLengthValue.$params.max]);
      case 'minValue':
        return t('form.error.minValue', [fieldDisplayName, field, ruleParams.minValue.$params.min]);
      case 'url':
        return t('form.error.url');
      case 'isDate':
        return t('form.error.date');
      case 'isTrue':
        return t('form.error.boolean', [fieldDisplayName]);
      case 'isNotNullBoolean':
        return t('form.error.not_null_boolean', [fieldDisplayName]);
      default:
        return t('form.error.unknown', [fieldDisplayName, validatorName]);
    }
  }

  return {
    getErrorMessage
  }
}
