import {fileDownloader} from "@/api/fileDownloader";
import {Ajax} from "@/util/ajaxLoader";

const FILE_INFO_PATH = 'api/file/info/';
const FILE_DOWNLOAD_PATH = 'api/file/';
const FILE_BY_OBJECT_PATH = 'api/file/object/';

class UploadedFile {
  constructor () {
    this.ajax = Ajax.getInstance()
  }

  getFileInfo (propertyId) {
    return this.ajax.sendGet(`${FILE_INFO_PATH}${propertyId}`)
  }

  downloadFile (propertyId, fileName) {
    return fileDownloader.downloadFile(`${FILE_DOWNLOAD_PATH}${propertyId}`, fileName)
  }

  fetchFileInBase64 (propertyId, fileName) {
    return fileDownloader.fetchFileInBase64(`${FILE_DOWNLOAD_PATH}${propertyId}`, fileName)
  }

  deleteFileWithProperty (objectId, attributeCode) {
    return this.ajax.sendDelete(`${FILE_BY_OBJECT_PATH}${objectId}/${attributeCode}`)
  }
}

export const uploadedFileApi = new UploadedFile();
