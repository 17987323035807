<template>
  <div class="mcf-container">
    <div class="mcf-show-threaded-objects-page">
      <h1 class="mcf-objects-title">{{ $t('showMyObjectsPage.title') }}</h1>
      <div v-if="Boolean(objects) && objects.length"
           class="mcf-show-threaded-objects-page">
        <panel-object-list
          :objects="objects"
          :with-additional-object-info="false"/>
      </div>
      <div v-else-if="Boolean(objects) && objects.length === 0">
        <panel-no-object-renderer/>
      </div>
    </div>
  </div>
</template>

<script setup>
import PanelObjectList from "@/views/object/PanelObjectList.vue";
import PanelNoObjectRenderer from "@/components/patientData/objects/PanelNoObjectRenderer.vue";
import {onMounted, ref} from "vue";
import {ObjectsApi} from "@/api/objects";
import {useRoute} from "vue-router";

//state
const route = useRoute()
const objects = ref(null)

//lifecycle
onMounted(async () => {
  objects.value = await ObjectsApi.getInstance().getThreadObjects(route.params.threadId)
})
</script>

<style scoped lang="scss">
.mcf-show-threaded-objects-page {
  width: 100%;
}

</style>
