import {Collection} from 'vue-mc-vue3'
import Attribute from "@/models/patientData/Attribute";
import {attributeDisplaySettingsApi} from "@/api/attributeDisplaySettings";
import AttributeDisplaySettingList from "@/models/patientData/AttributeDisplaySettingList";
import AttributeDisplaySetting from "@/models/patientData/AttributeDiplaySetting";

export default class AttributeList extends Collection {
  options() {
    return {
      model: Attribute
    }
  }

  async sortByOrderIndex() {
    let attrIds = []

    for (let i = 0; i < this.models.length; i++) {
      attrIds.push(this.models[i].id)
    }

    let response = await attributeDisplaySettingsApi.getInstance().getDisplaySettingsByAttributeList(attrIds)
    let attributeDisplaySettingList = []
    if (response.data != null) {
      attributeDisplaySettingList = new AttributeDisplaySettingList(
        response.data.map((prop) => new AttributeDisplaySetting(prop))
      )
    }

    for (let i = 0; i < this.models.length; i++) {
      let currentAttrSettings = attributeDisplaySettingList.find(displaySetting =>
        displaySetting.attributeId === this.models[i].id
      )
      if (currentAttrSettings != null) {
        this.models[i].currentDisplaySetting = currentAttrSettings
      }
    }

    this.sort((it) => {
      if (it.currentDisplaySetting) {
        return it.currentDisplaySetting.orderIndex
      }
    })
    return this
  }
}
