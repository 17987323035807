<template>
  <div class="mcf-content-text">
    <div class="mcf-content-item">
      <div class="mcf-content-h2">{{ $t('aboutPage.contacts.address') }}</div>
      <p>
        <span>Gerberngasse 27-31</span>
        <span>3011 Bern</span>
      </p>
    </div>

    <div class="mcf-content-item">
      <div class="mcf-content-h2">{{ $t('aboutPage.contacts.phone') }}</div>
      <p><a href="skype:+410313121100?call">+41 31 312 11 00</a></p>
    </div>

    <div class="mcf-content-item">
      <div class="mcf-content-h2">{{ $t('aboutPage.contacts.email') }}</div>
      <a href="mailto:info@medicosearch.ch">info@medicosearch.ch</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
@include mq(tablet-wide, max) {
  .mcf-content-item:deep(.mcf-content-h2) {
    font-family: $main-font;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
