<template>
  <div v-if="Boolean(objects)" class="mcf-show-permitted-object-page">
    <object-list :objects="objects"/>
  </div>
</template>

<script setup>
import ObjectList from "@/views/object/ObjectList.vue";
import {onMounted, ref} from "vue";
import {ObjectsApi} from "@/api/objects";

//state
const objects = ref(null)

//lifecycle
onMounted(async () => {
  objects.value = await ObjectsApi.getInstance().getPermittedObjects()
})
</script>
