<template>
  <div class="mcf-form-column"
       :class="setPropertiesClass">
    <label v-show="showAttributeName"
           class="mcf-label">{{ item.attribute.name }}:</label>
    <div class="mcf-value-state">
      <input type="text"
             v-model="form.value"
             class="mcf-input"
             @focusin="dontShowValidationError"
             @focusout="showValidationError"
             @input="updateValue"/>
    </div>
    <div v-if="v$ && item.isShowError"
         class="mcf-validation-errors-wrapper">
      <div v-for="($error, i) in v$.$errors"
           :key="i"
           class="mcf-validation-errors">
        <div class="mcf-validation-error">
          {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, toRef, unref} from "vue";
import {useVuelidate} from "@vuelidate/core";
import {decimal, required} from '@vuelidate/validators'
import ObjectData from "@/components/patientData/ObjectData";
import {useValidationErrors} from "@/composables/useValidationErrors";
import {ActiveClasses} from "@/components/patientData/ActiveClasses";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const {getErrorMessage} = useValidationErrors()
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const autoFillData = toRef(props, 'autoFillData');
const form = reactive({value: unref(property)?.value ?? ""});
const rules = computed(() => ({
  form: {
    value: {
      decimal,
      required: unref(item).attribute.currentDisplaySetting.required ? required : () => true
    }
  }
}))
const v$ = useVuelidate(rules, {form})
const showAttributeName = computed(() => !unref(item).attribute.list)
const setPropertiesClass = computed(() => {
  let className = ''
  if (v$.value.form.$invalid && unref(item).isShowError) {
    className += ` ${ActiveClasses.ERROR_CLASS}`
  }
  if (form.value !== null) {
    className += ` ${ActiveClasses.FILL_CLASS}`
  }

  if (form.value === '') {
    className -= ` ${ActiveClasses.FILL_CLASS}`
  }
  return className
})

//methods
function setFormValue() {
  item.value.validator = v$
  item.value.isReadyToModify = true

  if (unref(property)?.value == null) {
    if (unref(autoFillData)?.value != null) {
      property.value.value = unref(autoFillData).value
    } else {
      property.value.value = null
    }
  }
  form.value =unref(property).value
}

function updateValue() {
  property.value.value = form.value
}

function showValidationError() {
  item.value.isShowError = true
}

function dontShowValidationError() {
  item.value.isShowError = false
}

//lifecycle
onMounted(() => {
  setFormValue()
})
</script>
