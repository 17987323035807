<template>
  <div v-if="isRender"
       class="mcf-input-wrapper mcf-datetime-wrapper">
    <label v-show="showAttributeName"
           class="mcf-label">{{ item.attribute.name }}:</label>
    <div class="mcf-input-datetime-wrapper">
      <input type="text"
             class="mcf-input"
             :placeholder="$t('field.day')"
             v-model="form.day"
             @input="updateValue"/>
      <input type="text"
             class="mcf-input"
             :placeholder="$t('field.month')"
             v-model="form.month"
             @input="updateValue"/>
      <input type="text"
             class="mcf-input"
             :placeholder="$t('field.year')"
             v-model="form.year"
             @input="updateValue"/>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref, toRef, unref} from "vue";
import ObjectData from "@/components/patientData/ObjectData";
import {DateFormatter} from "@/util/DateFormatter";

//properties
const props = defineProps({
  item: ObjectData,
  property: Object,
})

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const isRender = ref(false)
const form = reactive({
  year: null,
  month: null,
  day: null
})
const showAttributeName = computed(() => !unref(item).attribute.list)

//methods
const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value)
  }
}

function prepareItemAndProperty() {
  isRender.value = true
  item.value.isReadyToModify = true

  if (unref(property)?.value == null) {
    Object.assign(form, setForm(new Date()));
    property.value.value = DateFormatter.formatDate(new Date(Date.now()))
  }
}

function updateValue() {
  let updatedDate = new Date(form.year, form.month - 1, form.day)
  property.value.value = updatedDate.getTime()
}

//lifecycle
onMounted(() => {
  prepareItemAndProperty()
})
</script>
