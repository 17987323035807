<template>
  <div>
    <div v-for="(item, i) in renderData" :key="i">
      <component
        v-if="isShowItem(item)"
        :is="computePropertyTag(item)"
        :item="item"/>
    </div>
  </div>
</template>

<script setup>
import AttributeType from "@/components/patientData/AttributeTypes";
import EnumShow from "@/components/patientData/properties/show/EnumShow.vue";
import TextShow from "@/components/patientData/properties/show/TextShow.vue";
import LongTextShow from "@/components/patientData/properties/show/LongTextShow.vue";
import BooleanShow from "@/components/patientData/properties/show/BooleanShow.vue";
import LinkShow from "@/components/patientData/properties/show/LinkShow.vue";
import NumericShow from "@/components/patientData/properties/show/NumericShow.vue";
import DateShow from "@/components/patientData/properties/show/DateShow.vue";
import DateTimeShow from "@/components/patientData/properties/show/DateTimeShow.vue";
import ImmutableTextShow from "@/components/patientData/properties/show/ImmutableTextShow.vue";
import UploadedShow from "@/components/patientData/properties/show/UploadedShow.vue";

defineProps({
  renderData: Array,
});

const componentsMap = {
  'enum': EnumShow,
  'text': TextShow,
  'long-text': LongTextShow,
  'boolean': BooleanShow,
  'link': LinkShow,
  'numeric': NumericShow,
  'date': DateShow,
  'date-time': DateTimeShow,
  'immutable-text': ImmutableTextShow,
  'uploaded': UploadedShow,
}

//methods
function computePropertyTag(item) {
  return componentsMap[AttributeType.resolveTypePrefix(item.attribute.attributeType)]
}

function isShowItem(item) {
  return item.attribute.currentDisplaySetting.showInList
}
</script>
