import {Ajax} from "@/util/ajaxLoader";

const AUTO_FILL = 'api/v0/autoFill'

class AutoFill {
  constructor () {
    this.ajax = Ajax.getInstance()
  }

  getLastValues (objectId) {
    let url = AUTO_FILL
    if (objectId != null) {
      url = `${url}/${objectId}`
    }
    return this.ajax.sendGet(url, {}, { 'Content-Type': 'application/json' })
  }
}

let autoFill = null

export const AutoFillApi = {
  getInstance () {
    if (autoFill == null) {
      autoFill = new AutoFill()
    }
    return autoFill
  }
}
