<template>
  <div class="mcf-static-wrap">
    <div class="mcf-container">
      <div class="mcf-tab-content">
        <div class="mcf-flex-row">
          <div class="mcf-flex-l">
            <h1 v-html="$t('informationPage.title')"></h1>
            <div class="mcf-static-img-mob"><img src="@/assets/images/static/doctor@2x.png"/></div>
            <div class="mcf-content-text">
              <p v-html="$t('informationPage.txt1')"></p>
              <p v-html="$t('informationPage.txt2')"></p>
              <ul>
                <li v-html="$t('informationPage.list1')"></li>
                <li v-html="$t('informationPage.list2')"></li>
                <li v-html="$t('informationPage.list3')"></li>
                <li v-html="$t('informationPage.list4')"></li>
              </ul>
            </div>
          </div>
          <div class="mcf-flex-r">
            <img src="@/assets/images/static/doctor@2x.png"/>
          </div>
        </div>
      </div>
    </div>
    <footer-element/>
  </div>
</template>

<script setup>
import FooterElement from "@/components/footer/FooterElement.vue";
import {onMounted} from "vue";
import {useHead} from "@vueuse/head";
import {useI18n} from "vue-i18n";

//state
const {t} = useI18n();

//lifecycle
onMounted(() => {
  window.scrollTo(0, 0)
})

//meta
useHead({
  title: t('page.title.MedicoFolder', [t('informationPage.title')]),
});
</script>

<style scoped lang="scss">
.mcf-static-wrap {
  background: #fff;
  min-height: calc(100vh - 80px);
}

.mcf-tab-content {
  width: 100%;
  padding: 40px 24px 50px;
}

.mcf-content-text {
  &, p {
    font-size: 18px;
    color: $static-color;
    line-height: 1.9;
    font-family: $main-font;
    font-weight: 400;
  }

  & {
    margin: 0 0 200px;
  }

  p {
    margin: 0 0 35px;
  }

  .mcf-static-small {
    font-size: 15px;
    font-weight: 500;
    color: #B3B7BA;
  }

  &:deep(a:not(.mcf-btn)) {
    color: $link-color;
    @include mq(tablet-wide, min) {
      &:hover {
        color: $link-hover-color;
      }
    }
  }

  h2,
  .mcf-content-h2 {
    font-family: $main-font;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 12px;
    line-height: 1.1;
  }

  ul {
    margin: 0 0 35px;
    padding: 0;

    li {
      position: relative;
      margin: 0 0 28px;
      list-style-type: none;
      padding: 0 0 0 20px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 12px;
        width: 7px;
        height: 7px;
        background: $link-color;
        border-radius: 50%;
      }
    }
  }
}

.mcf-flex-row {
  @include flexbox;
}

.mcf-flex-l {
  width: 49%;
}

.mcf-flex-r {
  width: 51%;
  padding: 0 0 0 6%;
  margin: -44px 0 0;
  text-align: right;

  img {
    width: 100%;
  }
}

.mcf-static-img-mob {
  display: none;
}

.mcf-static-wrap h1.mcf-static-title-mob {
  display: none;
}

@include mq(tablet-wide, max) {
  .mcf-static-wrap {
    .mcf-flex-r {
      display: none;
    }

    .mcf-flex-l {
      width: 100%;
    }

    .mcf-content-text {
      &, p {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 20px;
      }

      ul li {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 7px;
      }
    }

    &.mcf-about-static h1 {
      display: none;

      &.mcf-static-title-mob {
        display: block;
      }
    }
  }
  .mcf-static-img-mob {
    display: block;
    max-width: 263px;
    margin: 0 auto 24px auto;
  }

  .mcf-tab-content h1 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }
}
</style>
