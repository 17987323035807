<template>
  <form @submit.prevent="saveObject" enctype="multipart/form-data">
    <div v-for="(item, i) in renderData" :key="i">
      <div class="mcf-list-wrap"
           v-if="allowShowAsList(item)">
        <label class="mcf-label">{{ item.attribute.name }}:</label>
        <vue-draggable-next :list="item.properties">
          <transition-group>
            <div class="mcf-flex-row-with-btn mcf-flex-align-start"
                 v-for="property in item.properties"
                 :key="property.index">
              <component
                :is="computePropertyTag(item)"
                :item="item"
                :property="property"/>
              <template v-if="errors.length > 0">
                <div v-for="(error, j) in errors"
                     :key="j">
                     class="error">
                  <span>{{ error }}</span>
                </div>
              </template>
              <div class="mcf-list-actions">
                <a href="javascript:;"
                   class="mcf-icon mcf-icon-trash"
                   @click.prevent="removeProperty(item, property.index)"></a>
              </div>
            </div>
          </transition-group>
        </vue-draggable-next>
        <input class="mcf-button-transparent mcf-button-sm"
               @click.prevent="addProperty(item)"
               type="button"
               :value="$t('attribute.list.add.label')"/>
        <br>
      </div>
      <div v-else>
        <component
          :is="computePropertyTag(item)"
          :item="item"
          :property="item.properties[0]"/>
        <template v-if="errors.length > 0">
          <div v-for="(error, i) in errors"
               :key="i"
               class="error">
            <span>{{ error }}</span>
          </div>
        </template>
      </div>
    </div>
    <div class="mcf-button-wrapper mcf-button-center">
      <input
        type="submit"
        class="mcf-button-fill"
        :value="$t('general.save')">
    </div>
  </form>
</template>

<script setup>
import {ref, toRef} from "vue";
import AttributeType from "@/components/patientData/AttributeTypes";
import {ObjectsApi} from "@/api/objects";
import {useRouter} from "vue-router";
import Property from "@/models/patientData/Property";
import TextCreate from "@/components/patientData/properties/create/TextCreate.vue";
import LongTextCreate from "@/components/patientData/properties/create/LongTextCreate.vue";
import BooleanCreate from "@/components/patientData/properties/create/BooleanCreate.vue";
import NotNullBooleanCreate from "@/components/patientData/properties/create/NotNullBooleanCreate.vue";
import EnumCreate from "@/components/patientData/properties/create/EnumCreate.vue";
import LinkCreate from "@/components/patientData/properties/create/LinkCreate.vue";
import NumericCreate from "@/components/patientData/properties/create/NumericCreate.vue";
import DateCreate from "@/components/patientData/properties/create/DateCreate.vue";
import DateTimeCreate from "@/components/patientData/properties/create/DateTimeCreate.vue";
import ImmutableTextCreate from "@/components/patientData/properties/create/ImmutableTextCreate.vue";
import UploadedCreate from "@/components/patientData/properties/create/UploadedCreate.vue";
import {VueDraggableNext} from "vue-draggable-next";

// Props
const props = defineProps({
  renderData: Array,
});

//state
const renderData = toRef(props, 'renderData');
const errors = ref([])
const router = useRouter()
const componentsMap = {
  'text': TextCreate,
  'long-text': LongTextCreate,
  'boolean': BooleanCreate,
  'not-null-boolean': NotNullBooleanCreate,
  'enum': EnumCreate,
  'link': LinkCreate,
  'numeric': NumericCreate,
  'date': DateCreate,
  'date-time': DateTimeCreate,
  'immutable-text': ImmutableTextCreate,
  'uploaded': UploadedCreate,
}

//methods
function allowShowAsList(item) {
  const attributeType = item.attribute.attributeType;
  return item.attribute.list
    && attributeType !== AttributeType.BOOLEAN
    && attributeType !== AttributeType.NOT_NULL_BOOLEAN
    && attributeType !== AttributeType.IMMUTABLE_TEXT
    && attributeType !== AttributeType.UPLOADED
}

function computePropertyTag(item) {
  return componentsMap[AttributeType.resolveTypePrefix(item.attribute.attributeType)]
}

async function saveObject() {
  let data = {}
  let isSubmit = true

  renderData.value.forEach(objectData => {
    if (!objectData.isReadyToModify) {
      isSubmit = false
      return
    }
    data[String(objectData.attribute.id)] = getAttributeValue(objectData)
  })

  if (isSubmit) {
    const response = await ObjectsApi.getInstance().createObject(data)
    await router.push({path: '/object/show/' + response.data.id})
  }
}

function getAttributeValue(objectData) {
  const attrType = objectData.attribute.attributeType

  if (objectData.attribute.list) {
    return objectData.properties
      .sort((p1, p2) => p1.index - p2.index)
      .map(property => parsePropertyValue(attrType, property.value))
  }
  const property = objectData.properties[0]
  return parsePropertyValue(attrType, property.value)
}

function parsePropertyValue(attrType, value) {
  if ((attrType === AttributeType.DATE || attrType === AttributeType.DATE_TIME) && value != null) {
    value = Number(new Date(value).getTime())
  }
  return value
}

function addProperty(item) {
  let newProperty = new Property()
  let propertiesLength = item.properties.length
  if (item.properties.length > 0) {
    newProperty.index = Math.max(...item.properties.map(prop => prop.index)) + 1
  } else {
    newProperty.index = 0
  }
  item.properties[propertiesLength] = newProperty
}

function removeProperty(item, index) {
  item.properties.splice(index, 1);
}
</script>
