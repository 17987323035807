<template>
  <div>
    <div class="mcf-label">{{ item.attribute.name }}:</div>
    <div class="mcf-upload-container">
      <file-pond
        name="file"
        ref="pond"
        v-bind:server="server"
        v-bind:files="myFiles"
        :label-idle="$t('form.selectFile')"
        :allow-multiple="false"/>
    </div>
  </div>
</template>

<script setup>
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import ObjectData from "@/components/patientData/ObjectData";
import {onMounted, ref, toRef, unref} from "vue";
import {uploadedFileApi} from "@/api/uploadedFiles";
import {Ajax} from "@/util/ajaxLoader";

// FilePond Plugins
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

// Props
const props = defineProps({
  objectId: Number,
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const objectId = toRef(props, 'objectId');
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const myFiles = ref([]);
const server = ref({
  url: process.env.VUE_APP_HEALTHBANK_HOST,
  process: sendFile,
  revert: revert,
  remove: (source, load, error) => {
    property.value.value = null;
    load()
  }
});

//methods
function setFormValue() {
  item.value.isReadyToModify = true

  if (property.value.id != null) {
    let resp = uploadedFileApi.getFileInfo(unref(property).id)
    try {
      let fileData = {
        source: resp.data.name,
        options: {
          type: 'local',
          file: {
            ...resp.data
          }
        }
      }
      myFiles.value.push(fileData)
    } catch (e) {
      //expected url exception
    }
  }
}

async function revert(uniqueFileId, load, error) {
  await removeFile(uniqueFileId, load, error)
}

async function removeFile(uniqueFileId, load, error) {
  await uploadedFileApi.deleteFileWithProperty(unref(objectId), unref(item).attribute.attributeCode)
  property.value.value = null
  item.value.isReadyToModify = true
  load()
}

async function sendFile(fieldName, file, metadata, load, error, progress, abort) {
  item.value.isReadyToModify = false;

  const formData = new FormData();
  formData.append(fieldName, file, file.name);

  let request = new XMLHttpRequest();
  request.open('POST', `${process.env.VUE_APP_HEALTHBANK_HOST}api/file/object/${objectId.value}?attributeCode=${item.value.attribute.attributeCode}`)

  const headers = await Ajax.getDefaultHeaders();
  Object.entries(headers).forEach(([key, value]) => request.setRequestHeader(key, value));

  request.upload.onprogress = (e) => progress(e.lengthComputable, e.loaded, e.total);

  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      let fileId = JSON.parse(request.responseText).id;
      property.value.value = fileId;
      item.value.isReadyToModify = true;
      file.id = fileId;
      load(fileId);
    } else {
      error('Upload failed');
    }
    item.value.isReadyToModify = true;
  };

  request.send(formData);

  return {
    abort: () => {
      request.abort();
      abort();
    }
  };
}

//lifecycle
onMounted(() => {
  setFormValue()
})
</script>
