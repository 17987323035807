<!--
use example:
<spinner-element :loading="true" :color="#000" :size="20px"/>
props:
  position: relative | absolute
-->
<template>
  <div
    v-show="loading"
    :style="[styleHandler()]"
    class="mcf-spinner"
    :class="{'mcf-onloading': textOnLoadLoadingEnabled}">
    <div :style="[spinnerStyle]" class="mcf-icon mcf-icon-med"></div>
    <div v-if="textOnLoadLoadingEnabled" class="mcf-spinner-loading">
      <i class="mcf-icon mcf-icon-exclamation-mark"></i>
      {{ $t(textOnLongLoading) }}
    </div>
  </div>
</template>

<script setup>
//properties
import {onMounted, ref} from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  showTextOnLongLoading: {
    type: Boolean,
    default: false
  },
  textOnLongLoading: {
    type: String,
    default: 'spinner.document.is.loading'
  },
  position: {
    type: String,
    default: 'relative'
  },
  color: {
    type: String,
    default: '#5E7EF2'
  },
  size: {
    type: String,
    default: '50px'
  }
})

//state
const spinnerWrapperStyle = {
  position: props.position,
  height: 'initial',
  width: 'initial',
  background: 'transparent',
}
const spinnerStyle = {
  color: props.color,
  fontSize: props.size
}
const textOnLoadLoadingEnabled = ref(false)
const timeoutForShowAdditionalText = 5000

function styleHandler() {
  if (props.position === 'absolute') {
    spinnerWrapperStyle.top = '50%'
    spinnerWrapperStyle.left = '50%'
    spinnerWrapperStyle['-webkit-transform'] = 'translate(-50%,-50%)'
    spinnerWrapperStyle['-moz-transform'] = 'translate(-50%,-50%)'
    spinnerWrapperStyle['-ms-transform'] = 'translate(-50%,-50%)'
    spinnerWrapperStyle.transform = 'translate(-50%,-50%)'
  }
  return spinnerWrapperStyle
}

//lifecycle
onMounted(() => {
  if (props.showTextOnLongLoading) {
    setTimeout(() => {
      if (props.loading) {
        textOnLoadLoadingEnabled.value = true
      }
    }, timeoutForShowAdditionalText)
  }
})
</script>

<style lang="scss" scoped>
.mcf-spinner {
  position: relative;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  height: 80px;
  width: 80px;
  margin: 0 auto;
  @include transition(all 0.3s ease-in-out 0);
  z-index: 100;

  &.mcf-onloading {
    @include flex-direction(column-reverse);
  }

  .mcf-icon-med {
    @include animation(mcf-scaleout 1.0s infinite ease-in-out);
  }

  &-loading {
    font-weight: 500;
    color: $light-grey;
    text-align: center;
    padding: 0 0 20px;

    .mcf-icon {
      @include inline-flex;
      @include align-items(center);
      @include justify-content(center);
      height: 18px;
      width: 18px;
      font-size: 10px;
      margin: 0 10px 0 0;
      color: $no-completed-color;
      border-radius: 50%;
      border: 1px solid $no-completed-color;
    }
  }
}

@include keyframes(mcf-scaleout) {
  0% {
    @include transform(scale(0));
    opacity: 1;
  }
  100% {
    @include transform(scale(1.0));
    opacity: 0;
  }
}
</style>
