import {Model} from 'vue-mc-vue3'
import {Ajax} from '@/util/ajaxLoader'
import Type from "@/models/patientData/Type";
import AttributeCategory from "@/models/patientData/AttributeCategory";
import AttributeDisplaySettingList from "@/models/patientData/AttributeDisplaySettingList";
import AttributeDisplaySetting from "@/models/patientData/AttributeDiplaySetting";


export default class Attribute extends Model {

  defaults() {
    return {
      id: null,
      name: '',
      typeId: null,
      list: false,
      attributeType: '',
      attributeCategoryId: null,
      attributeCode: '',
      currentDisplaySetting: null,
      enumeratedValuesId: null,
      nestedTypeTag: null
    }
  }

  mutations() {
    return {
      id: (id) => Number(id) || null,
      name: String,
      typeId: (id) => Number(id) || null,
      list: (value) => Boolean(value) || false,
      attributeType: String,
      attributeCategoryId: (id) => Number(id) || null,
      attributeCode: String,
      enumeratedValuesId: (id) => Number(id) || null,
    }
  }

  async getType() {
    if (this.typeId != null) {
      let type = new Type({id: this.typeId})
      return type.fetch().then(() => type)
    }
    return Promise.reject()
  }

  async getAttributeCategory() {
    if (this.attributeCategoryId != null) {
      let attributeCategory = new AttributeCategory({id: this.attributeCategoryId})
      return attributeCategory.fetch().then(() => attributeCategory)
    }
    return Promise.reject()
  }

  getAttributeDisplaySettings() {
    return Ajax
      .getInstance()
      .sendGet(`${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/attributeDisplaySetting/attribute/${this.id}`)
      .then((response) => {
        if (response.data == null) {
          return []
        }
        return new AttributeDisplaySettingList(
          response.data.map(displaySetting => new AttributeDisplaySetting(displaySetting))
        )
      })
      .catch((error) => {
        console.log('Failed try to sendGet type attributes!')
        return null
      })
  }

  async fetch(options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders()
    options.headers = {...options.headers, ...defaultHeaders}
    return super.fetch(options)
  }

  routes() {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/attribute/{id}`,
    }
  }
}
