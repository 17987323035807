<template>
  <div v-if="isShow"
       class="mcf-base-pagination">
    <base-pagination-navigation-button
      :disabled="isPreviousButtonDisabled"
      @click="previousPage">
      <i class="mcf-pagination-prev mcf-pagination-icon mcf-icon-chevron-up"></i>
    </base-pagination-navigation-button>

    <base-pagination-trigger
      v-for="paginationTrigger in paginationTriggers"
      :class="{'mcf-pagination-current': paginationTrigger === currentPage + 1}"
      :key="paginationTrigger"
      :pageNumber="paginationTrigger"
      @load-by-page="onLoadPage"
    />

    <base-pagination-navigation-button
      :disabled="isNextButtonDisabled"
      @click="nextPage">
      <i class="mcf-pagination-next mcf-pagination-icon mcf-icon-chevron-up"></i>
    </base-pagination-navigation-button>
  </div>
</template>

<script setup>
import {computed, ref, toRef} from "vue";
import BasePaginationTrigger from "@/views/pagination/BasePaginationTrigger.vue";
import BasePaginationNavigationButton from "@/views/pagination/BasePaginationNavigationButton.vue";

//properties
const props = defineProps({
  currentPage: {
    type: Number,
    required: true
  },
  pageCount: {
    type: Number,
    required: true
  },
  size: {
    type: Number,
    default: 5
  }
})

//state
const currentPage = toRef(props, 'currentPage');
const pageCount = toRef(props, 'pageCount');
const isShow = ref(true)
const isPreviousButtonDisabled = computed(() => currentPage.value === 0)
const isNextButtonDisabled = computed(() => currentPage.value === pageCount.value - 1)
const paginationTriggers = computed(() => {
  const size = props.size
  const visiblePagesThreshold = Math.ceil((size - 1) / 2)
  let pagintationTriggersArray = Array(props.size - 1).fill(0)

  if (pageCount.value < 2) {
    isShow.value = false
  }
  if (pageCount.value <= size) {
    pagintationTriggersArray = Array(pageCount.value).fill(0)
    pagintationTriggersArray[0] = 1
    const pagintationTriggers = pagintationTriggersArray.map(
      (paginationTrigger, index) => {
        return pagintationTriggersArray[0] + index
      }
    )
    return pagintationTriggers
  }

  if (currentPage.value <= visiblePagesThreshold + 1) {
    pagintationTriggersArray[0] = 1
    const pagintationTriggers = pagintationTriggersArray.map(
      (paginationTrigger, index) => {
        return pagintationTriggersArray[0] + index
      }
    )

    pagintationTriggers.push('optionsStart', pageCount.value)
    return pagintationTriggers
  }

  if (currentPage.value >= pageCount.value - visiblePagesThreshold + 1) {
    const pagintationTriggers = pagintationTriggersArray.map(
      (paginationTrigger, index) => {
        return pageCount.value - index
      }
    )
    pagintationTriggers.reverse().unshift(1, 'optionsEnd')
    return pagintationTriggers
  }

  pagintationTriggersArray[0] = currentPage.value - visiblePagesThreshold + 1
  const pagintationTriggers = pagintationTriggersArray.map(
    (paginationTrigger, index) => {
      return pagintationTriggersArray[0] + index
    }
  )
  pagintationTriggers.unshift(1, 'optionsStart')
  pagintationTriggers.push('optionsEnd', pageCount.value)
  return pagintationTriggers
})

//emits
const emit = defineEmits(['load-next-page', 'load-by-page', 'load-previous-page'])

//methods
function nextPage() {
  emit('load-next-page')
}

function onLoadPage(value) {
  emit('load-by-page', value - 1)
}

function previousPage() {
  emit('load-previous-page')
}

</script>

<style scoped lang="scss">
.mcf-base-pagination {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  padding: 20px 0;
}
</style>
