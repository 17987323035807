<template>
  <div>
    <!--remove object-->
    <modal-direction
      v-if="deleteObjectModalOpen"
      :is-open="deleteObjectModalOpen"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon mcf-modal-header-decline">
          <em class="mcf-icon mcf-icon-trash-fill"></em>
        </div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{ $t('object.delete.popup.title.delete_form') }}</h1>
        <div class="mcf-modal-subtitle">{{ $t('object.delete.popup.subtitle.delete_form') }}</div>
      </template>
      <template v-slot:buttons>
        <a href="javascript:;"
           class="mcf-button-transparent-gray"
           @click.prevent="hideModal">{{ $t('general.cancel') }}</a>
        <a href="javascript:;"
           class="mcf-button-fill"
           @click.prevent="onDeleteOwnObject">{{ $t('general.delete') }}</a>
      </template>
    </modal-direction>

    <!--remove object success-->
    <modal-direction
      v-if="deleteObjectSuccessModalOpen"
      :is-open="deleteObjectSuccessModalOpen"
      :is-click-outside-allowed="false"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon">
          <em class="mcf-icon mcf-icon-check2" style="font-size: 17px"></em>
        </div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{ $t('object.delete.popup.mainTitle.delete_success') }}</h1>
        <div class="mcf-modal-subtitle">{{ $t('object.delete.popup.title.delete_success') }}</div>
      </template>
      <template v-slot:buttons>
        <a
          href="/show-my-objects"
          class="mcf-button-transparent">{{ $t('general.close') }}</a>
      </template>
    </modal-direction>

    <div v-if="isRender"
         class="mcf-info">
      <div class="mcf-object-actions">
        <a v-if="!isEmbedUrl && renderMode === RenderMode.SHOW_PDF"
           href="javascript:;"
           class="mcf-icon mcf-icon-PDF"
           @click.prevent="downloadFile()"></a>
        <a v-if="!isEmbedUrl"
           href="javascript:;"
           class="mcf-icon mcf-icon-trash"
           @click.prevent="showModal"></a>
        <router-link
          v-if="!object.completed && renderMode !== 'modify'"
          class="mcf-icon mcf-icon-pen"
          :to="objectEditLink"
        />
      </div>

      <div class="mcf-info-head">
        <router-link :to="objectShowLink">{{ type.name }}</router-link>
      </div>
      <component
        :is="propertyRendererTag"
        :key="renderMode + (displaySetting ? displaySetting.id : '')"
        :renderData="renderData"
        :objectId="object.id"
        :isSignRequired="type.signRequired"
        :isObjectSigned="object.signed"
        :isObjectCompleted="object.completed"
        :display-setting="displaySetting"
        class="mcf-info-content"/>
    </div>
  </div>
</template>

<script setup>
import ModalDirection from "@/components/ModalDirection.vue";
import {Obj} from "@/models/patientData/Object";
import {computed, onMounted, ref, toRef, unref} from "vue";
import {useHead} from "@vueuse/head";
import {useRoute} from "vue-router";
import {ObjectsApi} from "@/api/objects";
import RenderMode from "@/components/patientData/RenderMode";
import {AutoFillApi} from "@/api/autoFill";
import {useAppStore} from "@/stores/useAppStore";
import AttributeType from "@/components/patientData/AttributeTypes";
import Property from "@/models/patientData/Property";
import ObjectData from "@/components/patientData/ObjectData";
import {Ajax} from "@/util/ajaxLoader";
import {pdfFileApi} from "@/api/pdfFile";
import PropertiesShowRenderer from "@/components/patientData/properties/PropertiesShowRenderer.vue";
import PropertiesModifyRenderer from "@/components/patientData/properties/PropertiesModifyRenderer.vue";
import PropertiesListRenderer from "@/components/patientData/properties/PropertiesListRenderer.vue";
import PropertiesPdfRenderer from "@/components/patientData/properties/PropertiesPdfRenderer.vue";
import PropertiesUploadedPdfRenderer from "@/components/patientData/properties/PropertiesUploadedPdfRenderer.vue";

//properties
const props = defineProps({
  object: Object || null,
  renderMode: String || null,
  updateObjectsListEventBus: null,
  displaySetting: null,
})

//state
const componentsMap = {
  'show': PropertiesShowRenderer,
  'modify': PropertiesModifyRenderer,
  'list': PropertiesListRenderer,
  'pdf': PropertiesPdfRenderer,
  'uploaded-pdf': PropertiesUploadedPdfRenderer,
}
const object = toRef(props, 'object');
const displaySetting = toRef(props, 'displaySetting');
const route = useRoute();
const renderData = ref([])
const isRender = ref(false)
const type = ref(null)
const deleteObjectModalOpen = ref(false)
const deleteObjectSuccessModalOpen = ref(false)
const pdfName = ref(null)
const pageTitle = ref('MedicoFolder')
const store = useAppStore()
const propertyRendererTag = computed(() => componentsMap[props.renderMode])
const isEmbedUrl = computed(() => route.query?.displayType === 'embed')
const isAutoFillEnabled = computed(() => !route.query || route.query.autoFill !== 'disabled')
const objectEditLink = computed(() => {
  const url = `/object/edit/${object.value.id}`;
  return isEmbedUrl.value ? `${url}?displayType=embed` : url;
})
const objectShowLink = computed(() => {
  const url = `/object/show/${object.value.id}`;
  return isEmbedUrl.value ? `${url}?displayType=embed` : url;
})

//emits
const emit = defineEmits(['is-rendered-object'])

//methods
function showModal() {
  deleteObjectModalOpen.value = true;
}

function hideModal() {
  deleteObjectModalOpen.value = false;
}

function updatePageTitle() {
  pageTitle.value = `${type.value.name} ${object.value.id} - MedicoFolder`
}

async function onDeleteOwnObject() {
  await ObjectsApi.getInstance()
    .deleteOwnObject(object.value.id)
    .then(() => hideModal())
    .then(() => deleteObjectSuccessModalOpen.value = true)
}

async function getObjectData() {
  await object.value.getType()
    .then(t => {
      type.value = t
      return type.value.getAttributes()
    })
    .then(attrs => {
      let actions = [
        attrs.sortByOrderIndex(),
        object.value.getProperties(),
      ]
      if (props.renderMode === RenderMode.MODIFY_ITEM && isAutoFillEnabled.value) {
        let autofill = AutoFillApi.getInstance()
          .getLastValues(object.value.id)
          .catch(() => {
            store.setPageNotFound(true)
            return Promise.reject()
          })
        actions.push(autofill)
      }
      return Promise.all(actions)
    })
    .then(async results => {
      renderData.value = await prepareAttributesAndPropertiesForRendering(results)
    });
}

async function getNestedObjectData(nestedObject) {
  return await nestedObject.getType()
    .then(t => t.getAttributes())
    .then(attrs => {
      let actions = [
        attrs.sortByOrderIndex(),
        nestedObject.getProperties(),
      ]
      if (props.renderMode === RenderMode.MODIFY_ITEM && isAutoFillEnabled.value) {
        let autofill = AutoFillApi.getInstance()
          .getLastValues(nestedObject.id)
          .catch(() => store.setPageNotFound(true))

        actions.push(autofill)
      }
      return Promise.all(actions)
    })
    .then(results => prepareAttributesAndPropertiesForRendering(results));
}

async function prepareAttributesAndPropertiesForRendering(results) {
  let attrs = results[0]
  let props = results[1]
  let autoFillOptions = null
  if (results[2] !== undefined) {
    autoFillOptions = results[2].data
  }
  let renderData = [];
  await attrs.each(async (attr, index) => {
    let properties = props.filter(property => property.attributeId === attr.id)
      .sort((p1, p2) => p1.index - p2.index)

    if (attr.list && attr.attributeType === AttributeType.NESTED_OBJECT && properties.length > 1) {
      for (let property of properties) {
        let preparedData = await prepareAttributePropertyForRendering(attr, index, [property], autoFillOptions)
        renderData.push(preparedData)
      }
    } else {
      let preparedData = await prepareAttributePropertyForRendering(attr, index, properties, autoFillOptions)
      renderData.push(preparedData)
    }

  })
  return renderData
}

async function prepareAttributePropertyForRendering(attr, attrIndex, properties, autoFillOptions) {
  if (!properties || properties.length === 0) {
    properties = [new Property()]
  }
  let attributeProp = properties.find(el => el.attributeId === attr.id)

  let autoFillData = getAutoFillData(attr.id, autoFillOptions)
  let nestedObjectData = null
  if (attr.attributeType === AttributeType.NESTED_OBJECT) {
    if (!attributeProp?.value) {
      let nestedObj = await createNestedObject(attr)
      attributeProp.value = nestedObj.id
    }
    let obj = new Obj({id: attributeProp.value})
    await obj.fetch();
    nestedObjectData = await getNestedObjectData(obj).then((data) => data)
  }
  return new ObjectData(attr, properties, autoFillData, false, false, nestedObjectData)
}

function getAutoFillData(attributeId, autoFillOptions) {
  let autoFillData = null

  if (props.renderMode === RenderMode.MODIFY_ITEM) {
    if (autoFillOptions != null && autoFillOptions.length !== 0) {
      autoFillData = autoFillOptions.filter(option => option.attributeId === attributeId)
    }
  }

  return autoFillData;
}

async function createNestedObject(attribute) {
  if (attribute.nestedTypeTag != null) {
    return Ajax.getInstance()
      .sendPost(`${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/object/${object.value.id}/attribute/${attribute.id}/nestedObject`)
      .then((response) => new Obj({id: response.data.id, typeId: response.data.typeId}))
      .catch((error) => {
        console.log('Failed try to sendPost createNestedObject!')
        return null
      })
  }
}

function downloadFile() {
  pdfFileApi.downloadFile(object.value.id, displaySetting.value.id, pdfName.value)
}

async function fetchPdfName() {
  let fileInfoResp = await pdfFileApi.getFileInfo(object.value.id, displaySetting.value.id)
  let fileInfo = fileInfoResp.data
  if (fileInfo.name != null) {
    pdfName.value = fileInfo.name
  }
}

//lifecycle
onMounted(async () => {
  await getObjectData()
  if (props.renderMode === RenderMode.SHOW_PDF) {
    await fetchPdfName()
  }
  isRender.value = true
  updatePageTitle()
  emit('is-rendered-object')
})

//meta
useHead({
  title: computed(() => unref(pageTitle))
})
</script>


<style scoped lang="scss">
.mcf-info {
  position: relative;
  margin: 0 0 20px;

  &-head {
    @include flexbox;
    font-family: $main-font;
    font-size: $info-head-size-mob;
    font-weight: 600;
    color: $main-color;
    padding: $info-header-padding-mob;
    width: 77%;

    a {
      font-family: $main-font;
      font-size: $info-head-size-mob;
      font-weight: 600;
      color: $main-color;
    }

    @include mq(tablet-wide, min) {
      font-size: $info-head-size;
      padding: $info-header-padding;
      a {
        font-size: $info-head-size;
      }
    }
  }

  &-content {
    padding: $info-content-padding;
  }

  @include mq(tablet-wide, min) {
    margin: 0 0 10px;
  }
}

.mcf-object-actions {
  @include flexbox;
  @include justify-content(flex-end);
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 7px;

  a {
    @include inline-flex;
    @include align-items(center);
    @include justify-content(center);
    height: 28px;
    width: 28px;
    border: 1px solid #E6E9F1;
    border-radius: 2px;
    font-size: 16px;
    color: $main-color;
    margin: 0 0 0 5px;
    @include transition($main-transition);

    &.mcf-icon-pen {
      font-size: 16px;
    }
  }

  @include mq(tablet-wide, min) {
    padding-top: 10px;
    a {
      height: 32px;
      width: 32px;
      font-size: 20px;

      &:hover {
        background: rgba(94, 126, 242, .09);
      }
    }
  }
}
</style>
