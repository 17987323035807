<template>
  <div class="mcf-static-wrap mcf-static-license">
    <div class="mcf-container">
      <div class="mcf-tab-content">
        <h1>{{ tabName }}</h1>
        <Tabs>
          <h1 class="mcf-static-title-mob">{{ tabName }}</h1>
          <Tab
            :name="$t('legal.title')"
            :link="'/content/legal'"/>
          <Tab
            :name="$t('userAgreement.title')"
            :link="'/content/legal/userAgreement'"/>
        </Tabs>
        <Suspense>
          <router-view/>
        </Suspense>
      </div>
    </div>
    <footer-element/>
  </div>
</template>

<script setup>
import FooterElement from "@/components/footer/FooterElement.vue";
import Tabs from "@/components/tabs/Tabs.vue";
import Tab from "@/components/tabs/Tab.vue";
import {useHead} from "@vueuse/head";
import {computed, onMounted, ref, unref, watch} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

//state
const route = useRoute();
const {t} = useI18n();
const activeTab = ref(route.path);
const tabName = computed(() => t(`${getLastWord(activeTab.value)}.title`));
const pageTitle = computed(() => t('page.title.MedicoFolder', [t(`${getLastWord(activeTab.value)}.title`)]));

// Watchers
watch(() => route.path, (newPath) => {
  activeTab.value = newPath;
});

//methods
function getLastWord(path) {
  return path.split('/').filter(Boolean).pop();
}

//lifecycle
onMounted(() => {
  window.scrollTo(0, 0);
});

//meta
useHead({
  title: computed(() => unref(pageTitle)),
});
</script>

<style scoped lang="scss">
.mcf-static-wrap {
  background: #fff;
  min-height: calc(100vh - 80px);
}

.mcf-content-text {
  &, &:deep(p) {
    font-size: 18px;
    color: $static-color;
    line-height: 1.9;
    font-family: $main-font;
    font-weight: 400;
  }

  & {
    margin: 0 0 200px;
  }

  &:deep(p) {
    margin: 0 0 35px;
  }

  &:deep(a) {
    color: #5f7ef2;

    &:hover {
      color: #5F7EF2FF;
    }
  }

  &:deep(.msr-table-wrap) {
    max-width: 100%;
    overflow: auto;
  }

  &:deep(.msr-table-flex-table) {
    @include flexbox;
    flex-direction: column;
    border: 1px solid #333;
    width: 100%;
    max-width: 100%;
    min-width: 600px;
    margin: 20px auto;
    font-size: 15px;
  }

  &:deep(.msr-table-flex-row) {
    @include flexbox;
    border-top: 1px solid #333;

    &:first-child {
      border-top: none;
    }
  }

  &:deep(.msr-table-header) {
    font-family: $main-font;
    font-weight: 600;
    font-size: 16px;
  }

  &:deep(.msr-table-flex-cell) {
    padding: 10px;
    border-right: 1px solid #333;
  }

  &:deep(.msr-table-flex-cell:first-child) {
    flex: 1;
    width: 30%;
    @media (max-width: 991px) {
      word-break: break-all;
    }
  }

  &:deep(.msr-table-flex-cell:nth-child(2)) {
    width: 30%;
  }

  &:deep(.msr-table-flex-cell:nth-child(3)) {
    width: 40%;
    word-break: break-all;
  }

  &:deep(.msr-table-flex-cell:last-child) {
    border-right: none;
  }

  .mcf-static-small {
    font-size: 15px;
    font-weight: 500;
    color: #B3B7BA;
  }

  a:not(.mcf-btn) {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  &:deep(h2),
  &:deep(.mcf-content-h2) {
    font-family: $main-font;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 12px;
    line-height: 1.1;
  }

  ul {
    margin: 0 0 35px;
    padding: 0;

    li {
      margin: 0 0 28px;
      position: relative;
      list-style-type: none;
      padding: 0 0 0 20px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 12px;
        background: $link-color;
        width: 7px;
        height: 7px;
        border-radius: 50%;
      }
    }
  }
}

.mcf-flex-row {
  @include flexbox;
}

.mcf-flex-l {
  width: 49%;
}

.mcf-flex-r {
  width: 51%;
  padding: 0 0 0 6%;
  margin: -82px 0 0;
  text-align: right;

  img {
    width: 100%;
  }
}

.mcf-static-img-mob {
  display: none;
}

.mcf-static-wrap h1.mcf-static-title-mob {
  display: none;
}

@include mq(tablet-wide, max) {
  .mcf-static-wrap {
    .mcf-tab-content {
      width: 100%;
      padding: 40px 24px 50px;

      h1 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
      }
    }

    .msr-content-text {
      &, &:deep(p) {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 20px;
      }

      &:deep(ul li) {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 7px;
      }
    }

    .msr-content-text:deep(p) {
      font-size: 14px;
      line-height: 2.29;
      margin: 0 0 20px;
    }

    .msr-content-text:deep(h2) {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
  .mcf-static-license h1 {
    display: none;

    &.mcf-static-title-mob {
      display: block;
    }
  }
  .mcf-static-img-mob {
    display: block;
    max-width: 263px;
    margin: 0 auto 24px auto;
  }
}
</style>
