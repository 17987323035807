import {Ajax} from "@/util/ajaxLoader";

const DISPLAY_SETTINGS_LIST_PATH = 'api/v0/attributeDisplaySetting/attribute/list'

class AttributeDisplaySettings {
  constructor() {
    this.ajax = Ajax.getInstance()
  }

  async getDisplaySettingsByAttributeList(attributeIds) {
    if (attributeIds != null && attributeIds.length !== 0) {
      let ids = attributeIds.join(',')
      return this.ajax.sendGet(DISPLAY_SETTINGS_LIST_PATH, {
        'ids': ids
      }, {
        'Content-Type': 'application/json'
      })
    }
  }
}

let attributeDisplaySettings = null

export const attributeDisplaySettingsApi = {
  getInstance() {
    if (attributeDisplaySettings == null) {
      attributeDisplaySettings = new AttributeDisplaySettings()
    }
    return attributeDisplaySettings
  }
}
