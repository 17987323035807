<template>
  <div id="preview-wrapper"></div>
</template>

<script setup>
import * as PDFJS from "pdfjs-dist";
import {onMounted} from "vue";
import {Ajax} from "@/util/ajaxLoader";

// Props
const props = defineProps({
  pdfUrl: null
});

//emits
const emit = defineEmits(['is-rendered-object'])

//methods
function createNewPageCanvasWrapper(pageNumber) {
  let pageWrap = document.createElement("canvas");
  pageWrap.setAttribute("id", "canvas-wrapper-" + pageNumber);
  pageWrap.style.maxWidth = "100%";

  let previewWrap = document.getElementById('preview-wrapper');
  if (previewWrap) {
    previewWrap.appendChild(pageWrap);
  }

  return pageWrap;
}

function prepareContext(page) {
  let scale = 1.5;
  let viewport = page.getViewport({scale: scale});

  let canvas = createNewPageCanvasWrapper(page.pageIndex);
  let context = canvas.getContext('2d');
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  return {
    canvasContext: context,
    viewport: viewport
  };
}

function renderPdfPage(page) {
  let renderContext = prepareContext(page);
  page.render(renderContext);
}

async function renderPage(pdf, pageNumber) {
  let page = await pdf.getPage(pageNumber);
  renderPdfPage(page);
}

async function renderPdf(pdf) {
  const numPages = pdf.numPages;

  for (let i = 1; i <= numPages; i++) {
    await renderPage(pdf, i);
  }
}

//lifecycle
onMounted(async () => {
  PDFJS.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  const headers = await Ajax.getDefaultHeaders();

  const fileProps = {
    url: props.pdfUrl,
    httpHeaders: headers,
    withCredentials: true,
  };
  const loadingTask = PDFJS.getDocument(fileProps);
  const pdf = await loadingTask.promise;

  await renderPdf(pdf);
  emit('is-rendered-object')
})
</script>
