<template>
  <div>
    <span>{{ item.attribute.name }}</span>:
    <div class="upload-container">
      <file-pond
        name="file"
        ref="pond"
        v-bind:server="server"
        v-bind:files="myFiles"
        :label-idle="$t('form.selectFile')"/>
    </div>
  </div>
</template>

<script setup>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import ObjectData from "@/components/patientData/ObjectData";
import {onMounted, ref, toRef, unref} from "vue";
import {uploadedFileApi} from "@/api/uploadedFiles";
import {Ajax} from "@/util/ajaxLoader";

// FilePond Plugins
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

// Props
const props = defineProps({
  item: ObjectData,
  property: Object
});

// State
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const myFiles = ref([]);
const server = ref({
  url: process.env.VUE_APP_HEALTHBANK_HOST,
  process: sendFile,
  revert: revert,
  remove: (source, load, error) => {
    property.value.value = null;
    load()
  }
});

// Methods
async function revert(uniqueFileId, load) {
  property.value.value = null;
  item.value.isReadyToModify = true;
  load();
}

async function sendFile(fieldName, file, metadata, load, error, progress, abort) {
  item.value.isReadyToModify = false;

  const formData = new FormData();
  formData.append(fieldName, file, file.name);

  let request = new XMLHttpRequest();
  request.open('POST', `${process.env.VITE_HEALTHBANK_HOST}file`);

  const headers = await Ajax.getDefaultHeaders();
  Object.entries(headers).forEach(([key, value]) => request.setRequestHeader(key, value));

  request.upload.onprogress = (e) => progress(e.lengthComputable, e.loaded, e.total);

  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      let fileId = JSON.parse(request.responseText).id;
      property.value.value = fileId;
      item.value.isReadyToModify = true;
      file.id = fileId;
      load(fileId);
    } else {
      error('Upload failed');
    }
    item.value.isReadyToModify = true;
  };

  request.send(formData);

  return {
    abort: () => {
      request.abort();
      abort();
    }
  };
}

// Lifecycle
onMounted(async () => {
  if (unref(property)?.id) {
    try {
      let resp = await uploadedFileApi.getFileInfo(unref(property).id);
      myFiles.value.push({
        source: resp.data.name,
        options: {type: 'local', file: {...resp.data}}
      });
    } catch (e) {
      // expected URL exception
    }
  }
  item.value.isReadyToModify = true;
});
</script>
