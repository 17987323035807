import {Collection} from 'vue-mc-vue3'
import {EnumeratedItemApi} from "@/api/enumeratedItem";
import EnumeratedItem from "@/models/patientData/EnumeratedItem";

export default class EnumeratedItemList extends Collection {

  options() {
    return {
      model: EnumeratedItem
    }
  }

  static getEnumeratedItemByEnumeratedValuesId(enumeratedValuesId) {
    return EnumeratedItemApi
      .getInstance()
      .getEnumeratedItemByEnumeratedValuesId(enumeratedValuesId)
      .then((response) => {
        if (response.data != null && response.data.length != null) {
          let list = new EnumeratedItemList()
          response.data.forEach((enumeratedItem) => {
            list.add(new EnumeratedItem(enumeratedItem))
          })
          return list
        }
        return null
      })
      .catch((e) => null)
  }
}
