import {Collection} from 'vue-mc-vue3'
import AttributeDisplaySetting from "@/models/patientData/AttributeDiplaySetting";

export default class AttributeDisplaySettingList extends Collection {
  options() {
    return {
      model: AttributeDisplaySetting
    }
  }
}
