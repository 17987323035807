<template>
  <div class="mcf-lang-selector-wrapper">
    <ul id="mcf-lang-selector">
      <template v-for="locale in $i18n.availableLocales"
                :key="locale">
        <li :class="{ active: $i18n.locale === locale }"
            class="mcf-btn-lang-switch">
          <a @click.prevent="onLocaleClick(locale)"
             href="javascript:void(0)">{{ locale }}</a>
        </li>
      </template>
    </ul>
    <modal-direction
      v-if="showReloadWarningWindow"
      :is-open="showReloadWarningWindow"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon mcf-modal-header-decline">
          <i class="mcf-icon mcf-icon-exclamation-mark" style="font-size: 17px"></i>
        </div>
      </template>
      <template v-slot:body>
        <div class="mcf-modal-subtitle">{{ $t('locale.change.warn.message') }}</div>
      </template>
      <template v-slot:buttons>
        <a href="javascript:;"
           class="mcf-button-transparent-gray"
           @click.prevent="hideModal">{{ $t('general.cancel') }}</a>
        <a href="javascript:;"
           class="mcf-button-fill"
           @click.prevent="acceptLocaleChangeInModal">{{ $t('general.continue') }}</a>
      </template>
    </modal-direction>
  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import ModalDirection from "@/components/ModalDirection.vue";
import {computed, ref} from "vue";

//state
const {locale} = useI18n();
const showReloadWarningWindow = ref(false);
const lastLocaleSelected = ref(locale.value);
const isWarningWindowNeeded = computed(() => {
  const url = window.location.pathname;
  return url.indexOf('object/edit/') !== -1 || url.indexOf('object/create/') !== -1;
})

//methods
function onLocaleClick(newLocale) {
  if (newLocale === locale.value) {
    return;
  }
  lastLocaleSelected.value = newLocale;
  if (isWarningWindowNeeded.value) {
    showReloadWarningWindow.value = true;
    return
  }
  locale.value = newLocale;
}

function hideModal() {
  showReloadWarningWindow.value = false;
}

function acceptLocaleChangeInModal() {
  hideModal()
  locale.value = lastLocaleSelected.value;
}
</script>

<style scoped lang="scss">
#mcf-lang-selector {
  @include flexbox;
}

.mcf-lang-selector-wrapper {
  position: absolute;
  bottom: 86px;
  left: 45px;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include mq(tablet-wide, min) {
    position: relative;
    left: initial;
    bottom: initial;
    padding: 10px 80px;
  }
}

.mcf-btn-lang-switch {
  a {
    position: relative;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    font-family: $main-font;
    font-size: 16px;
    font-weight: 500;
    color: rgba($white, 0.9);
    background-color: transparent;
    text-transform: uppercase;
    @include transition($main-transition);
    padding: 0px 13px;
    max-height: 31px;
    margin: 0 2px;
    border: 1px solid rgba($white, 0.3);
    border-radius: 8px;
  }

  &.active {
    a {
      color: $main-color;
      background: $white;
      border-color: $white;
    }
  }

  @include mq(tablet-wide, min) {
    a {
      font-size: 14px;
      font-weight: 600;
      color: $white;
      border: none;
      background: transparent;
      margin: 0 8px;
      padding: 0;
      max-height: initial;

      &:before {
        @include pseudo();
        bottom: -10px;
        left: -7px;
        right: -7px;
        height: 2px;
        background: transparent;
        @include transition($main-transition);
      }
    }
    &.active {
      a {
        color: $service-color;
        background: transparent;

        &:before {
          background: $service-color;
        }
      }
    }
    &:not(.active) a:hover {
      color: $service-color;

      &:before {
        background: $service-color;
      }
    }
  }
}

</style>
