import { Collection } from 'vue-mc-vue3'
import Tag from "@/models/patientData/Tag";

export default class TagList extends Collection {
  options () {
    return {
      model: Tag
    }
  }
}
