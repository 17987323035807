import { Collection } from 'vue-mc-vue3'
import {Obj} from "@/models/patientData/Object";

export default class ObjectList extends Collection {
  options () {
    return {
      model: Obj
    }
  }
}
