import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/views/Home.vue";
import AllContent from "@/components/AllContent.vue";
import NotFoundPage from "@/views/error/NotFoundPage.vue";
import Information from "@/components/static/Information.vue";
import About from "@/components/static/about/About.vue";
import Manifesto from "@/components/static/about/Manifesto.vue";
import Impressum from "@/components/static/about/Impressum.vue";
import Contacts from "@/components/static/about/Contacts.vue";
import License from "@/components/static/license/License.vue";
import Legal from "@/components/static/license/legal/Legal.vue";
import UserAgreement from "@/components/static/license/userAgreement/userAgreement.vue";
import ShowMyObjects from "@/views/ShowMyObjects.vue";
import ShowThreadedObjects from "@/views/ShowThreadedObjects.vue";
import ShowPermittedObjects from "@/views/ShowPermittedObjects.vue";
import ObjectEdit from "@/views/object/ObjectEdit.vue";
import ObjectShow from "@/views/object/ObjectShow.vue";
import ObjectCreate from "@/views/object/ObjectCreate.vue";

const _getLocale = (path) => {
  return path.replace(/^\/([^/]+).*/i, '$1')
}

export const setupRouter = (app) => {
  const i18n = app.config.globalProperties.$i18n
  let locale = _getLocale(window.location.pathname)
  if (i18n.availableLocales.indexOf(locale) === -1) {
    locale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
  }
  i18n.locale = locale

  const urls = [
    {
      path: 'content/about',
      component: About,
      props: true,
      children: [
        {
          path: "",
          component: Manifesto
        },
        {
          path: "impressum",
          component: Impressum
        },
        {
          path: "contacts",
          component: Contacts
        }
      ]
    },
    {
      path: 'content/information',
      component: Information
    },
    {
      path: 'content/legal',
      component: License,
      props: true,
      children: [
        {
          path: "",
          component: Legal
        },
        {
          path: "userAgreement",
          component: UserAgreement
        }
      ]
    },
    {
      path: 'show-my-objects',
      component: ShowMyObjects
    },
    {
      path: 'thread/:threadId',
      component: ShowThreadedObjects
    },
    {
      path: 'show-permitted-objects',
      component: ShowPermittedObjects,
    },
    {
      path: 'object/edit/:id',
      component: ObjectEdit,
    },
    {
      path: 'object/show/:id',
      component: ObjectShow,
    },
    {
      path: 'object/create/:typeId',
      component: ObjectCreate
    }
  ]

  const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/:locale(en|fr|it)?',
        component: Home
      },
      {
        path: '/:locale(en|fr|it)?',
        component: AllContent,
        children: urls
      },
      {
        path: '/:locale(en|fr|it)?/404',
        name: '404',
        component: NotFoundPage
      },
      {
        path: '/:locale(en|fr|it)?/:pathMatch(.*)*',
        component: NotFoundPage
      }
    ]
  })

  router.beforeEach((to, from, next) => {
    let urlLocale = _getLocale(to.path)
    if (i18n.availableLocales.indexOf(urlLocale) === -1) {
      urlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
    }

    if (i18n.locale !== urlLocale) {
      let newUrl = addLocalePrefixForUrl(to.fullPath, i18n.locale, urlLocale)
      router
        .push({
          path: newUrl,
          query: to.query,
          params: to.params
        })
        .catch(err => {
          if (err.name === 'NavigationDuplicated') {
            console.log('NavigationDuplicated ignored') //todo resolve rerender || tmp ignore
          }
        })
      return
    }
    next()
  })

  router.changeLocale = (newLocale) => {
    const url = window.location.pathname
    let currentUrlLocale = _getLocale(url)

    if (i18n.availableLocales.indexOf(currentUrlLocale) === -1) {
      currentUrlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
    }

    if (newLocale !== currentUrlLocale) {
      const newUrl = addLocalePrefixForUrl(url, newLocale, currentUrlLocale)
      router
        .push({
          path: newUrl,
          query: router.currentRoute.value.query,
          params: router.currentRoute.value.params
        })
        .then(() => window.location.reload())
        .catch((error) => console.warn("Failed to change locale:", error))
    }
  }

  function addLocalePrefixForUrl(url, newLocale, currentUrlLocale) {
    let newUrl = ''
    if (newLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
      newUrl = url.replace(`/${currentUrlLocale}`, '')
    } else if (currentUrlLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
      newUrl = `/${newLocale}${url}`
    } else {
      newUrl = url.replace(`/${currentUrlLocale}`, `/${newLocale}`)
    }

    if (!newUrl) {
      return '/'
    }
    return newUrl
  }

  return router
}
