<template>
  <div>
    <spinner-element :loading="!!isLoading"/>
    <div v-show="!isLoading"
         v-for="(item, i) in uploadedFileProperties"
         :key="i">
      <uploaded-show
        v-if="isShowItem(item)"
        :item="item"/>
    </div>
    <div>
      <div v-if="isSignRequired && !isObjectSigned && isObjectCompleted">
        {{ $t('object.sign_pdf.notification') }}
        <a href="javascript:"
           @click.prevent="onUploadSignedPdfLabelClick()">
          {{ $t('object.sign_pdf.upload') }}
        </a>
      </div>
      <div class="mcf-upload-container"
           style="margin-left:auto"
           v-if="showUploadSignedPdfForm">
        <file-pond
          name="file"
          ref="pond"
          v-bind:server="server"
          :label-idle="$t('form.selectFile')"
          accepted-file-types="application/pdf"/>
      </div>
    </div>
    <pdf-preview
      :pdf-url="pdfUrl"
      v-on:is-rendered-object="hideSpinner"/>
  </div>
</template>

<script setup>
import SpinnerElement from "@/components/SpinnerElement.vue";
import {useSpinner} from "@/composables/useSpinner";
import UploadedShow from "@/components/patientData/properties/show/UploadedShow.vue";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import {computed, ref, toRef} from "vue";
import AttributeType from "@/components/patientData/AttributeTypes";
import {Ajax} from "@/util/ajaxLoader";
import PdfPreview from "@/components/pdf/PdfPreview.vue";

// FilePond Plugins
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

// Props
const props = defineProps({
  renderData: Array,
  objectId: Number,
  displaySetting: null,
  isSignRequired: {
    type: Boolean,
    default: false,
  },
  isObjectSigned: {
    type: Boolean,
    default: false,
  },
  isObjectCompleted: {
    type: Boolean,
    default: false,
  },
});

//state
const renderData = toRef(props, 'renderData');
const showUploadSignedPdfForm = ref(false)
const server = ref({
  url: process.env.VUE_APP_HEALTHBANK_HOST,
  process: sendFile,
})
const pdfUrl = computed(() => {
  let downloadUrl = `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/pdf/download/${props.objectId}`
  if (props.displaySetting) {
    downloadUrl += `/${props.displaySetting.id}`
  }
  return downloadUrl
})
const uploadedFileProperties = computed(() => renderData.value.filter(it => it.attribute.get('attributeType') === AttributeType.UPLOADED))
const {
  isLoading,
  hideSpinner
} = useSpinner()

//methods
function isShowItem(item) {
  return item.attribute.attributeCode !== 'SIGNED_PDF'
}

function onUploadSignedPdfLabelClick() {
  if (props.isObjectSigned) return
  showUploadSignedPdfForm.value = !showUploadSignedPdfForm.value
}

async function sendFile(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
  const formData = new FormData()
  formData.append(fieldName, file, file.name)
  let request = new XMLHttpRequest()
  request.open('POST', `${process.env.VUE_APP_HEALTHBANK_HOST}api/file/sign/object/${props.objectId}`)

  const headers = await Ajax.getDefaultHeaders()
  for (let name in headers) {
    request.setRequestHeader(name, headers[name])
  }
  request.upload.onprogress = e => {
    progress(e.lengthComputable, e.loaded, e.total)
  }

  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      this.$router.go(0)
    } else {
      error('Upload failed');
    }
  }
  request.send(formData)
  return {
    abort: () => {
      request.abort()
      abort()
    }
  }
}
</script>
