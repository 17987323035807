<template>
  <Teleport to="body">
    <div v-if="isOpen"
         class="mcf-modal-backdrop">
      <div class="mcf-modal-wrapper">
        <div v-if="isClickOutsideAllowed"
             ref="modalRef"
             class="mcf-modal-container">
          <div class="mcf-modal-header">
            <div class="mcf-modal-close">
              <a
                href="javascript:void(0);"
                class="mcf-icon mcf-icon-x"
                @click.prevent="closeModal"></a>
            </div>
            <slot name="header"></slot>
          </div>
          <div class="mcf-modal-body">
            <slot name="body"></slot>
          </div>
          <div class="mcf-modal-buttons">
            <slot name="buttons"></slot>
          </div>
        </div>
        <div class="mcf-modal-container" v-else>
          <div class="mcf-modal-header">
            <slot name="header"/>
          </div>
          <div class="mcf-modal-body">
            <slot name="body"/>
          </div>
          <div class="mcf-modal-buttons">
            <slot name="buttons"/>
          </div>
        </div>
      </div>
    </div>
  </Teleport>

</template>

<script setup>
import {onMounted, onUnmounted, useTemplateRef} from "vue";
import {onClickOutside} from '@vueuse/core';

//properties
const props = defineProps({
  isOpen: Boolean,
  isClickOutsideAllowed: {
    type: Boolean,
    default: true
  }
});

//state
const modalRef = useTemplateRef('modalRef');

//emits
const emit = defineEmits(['modal-hide', 'modal-destroyed']);

//methods
onClickOutside(modalRef, () => {
  if (props.isClickOutsideAllowed) {
    closeModal();
  }
});

const closeModal = () => {
  emit('modal-hide');
};

//lifecycle
onMounted(() => {
  document.body.classList.add('mcf-active');
  document.body.style.overflow = 'hidden';
});

onUnmounted(() => {
  document.body.style.overflow = 'auto';
  document.body.classList.remove('mcf-active');
  emit('modal-destroyed');
});

</script>

<style lang="scss">
.mcf-modal {
  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#020f17, .64);
    z-index: 11;
  }

  &-wrapper {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100vh;
  }

  &-container {
    position: relative;
    @include flexbox;
    @include flex-direction(column);
    background: $white;
    @include box-shadow(0px 0px 40px rgba(0, 70, 67, 0.2));
    border-radius: 8px;
    min-height: 261px;
    width: 100%;
    max-width: 469px;
    padding: 16px 20px;
    margin: 0 $mobile-padding;
  }

  &-close {
    display: none;
    position: absolute;
    top: 22px;
    right: 22px;

    .mcf-icon {
      display: block;
      font-size: 14px;
      color: #C2C8CC;
      @include transition($main-transition);
      text-decoration: none;
    }
  }

  &-title {
    font-family: $main-font;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.78;
    color: $black;
    padding: 0 0 10px;
  }

  &-subtitle {
    font-family: $main-font;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.78;
    color: $black;
    padding: 3px 0;
  }

  &-body {
    @include flex-grow(1);
    padding: 5px 0;

    .mcf-input-wrapper {
      margin: 8px 0 0;
    }

    .mcf-label {
      font-size: 14px;
      font-weight: 600;
      color: #020F17;
    }
  }

  &-header-icon {
    text-align: center;
    padding: 5px 0;

    .mcf-icon {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      height: 48px;
      width: 48px;
      font-size: 23px;
      color: #5e7ef2;
      border-radius: 50%;
      background: #F1F3FE;
    }

    &.mcf-modal-header-decline {
      .mcf-icon {
        background: rgba(240, 130, 146, 0.14);
        color: #F08292;
      }
    }

    @include mq(tablet-wide, min) {
      padding: 20px 0 10px;
    }
  }

  &-buttons {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-end);
    @include flex-direction(column-reverse);
    padding: 5px 0;
  }

  @include mq(tablet-wide, min) {
    &-container {
      width: 469px;
      padding: 24px 24px;
      box-shadow: 0px 6.6501px 5.32008px rgba(7, 1, 48, 0.0282725), 0px 8px 10px rgba(7, 1, 48, 0.035), 0px 0px 10px rgba(7, 1, 48, 0.08);
    }
    &-close {
      display: block;

      .mcf-icon:hover {
        color: $main-color;
      }
    }
    &-title {
      font-size: 20px;
      line-height: 1.21;
    }
    &-header-icon {
      padding: 0 0 20px;
    }
    &-buttons {
      @include flex-direction(row);
      padding: 20px 0 0;
    }
  }
}

.mcf-button-transparent {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-family: $main-font;
  font-weight: 600;
  font-size: 14px;
  color: $main-color;
  line-height: 1.25;
  border: 1px solid $main-color;
  background: transparent;
  min-width: 124px;
  border-radius: $button-radius;
  padding: 10px 20px;
  margin: 6px 0;
  @include transition($main-transition);
  text-decoration: none;
  cursor: pointer;
  @include mq(tablet-wide, min) {
    font-size: 16px;
    margin: 0 0 0 12px;
    padding: 6px 20px;
    &:hover {
      color: $white;
      background: $main-color;
    }
  }
}

.mcf-button-transparent-gray {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-family: $main-font;
  font-weight: 600;
  font-size: 14px;
  color: #020F17;
  line-height: 1.25;
  border: 1px solid #C2C8CC;
  background: transparent;
  text-decoration: none;
  min-width: 124px;
  border-radius: $button-radius;
  padding: 10px 20px;
  margin: 6px 0;
  @include transition($main-transition);
  cursor: pointer;
  @include mq(tablet-wide, min) {
    font-size: 16px;
    margin: 0 0 0 12px;
    padding: 6px 20px;
    &:hover {
      background: #C2C8CC;
    }
  }
}

.mcf-button-fill {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-family: $main-font;
  font-weight: 600;
  font-size: 14px;
  color: $white;
  line-height: 1.25;
  border: 1px solid $main-color;
  background: $main-color;
  min-width: 124px;
  @include box-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  border-radius: $button-radius;
  padding: 10px 20px;
  margin: 6px 0;
  text-decoration: none;
  @include transition($main-transition);
  cursor: pointer;

  .mcf-icon {
    font-size: 18px;
    font-weight: 400;
    margin: -1px 0 -1px 15px;
  }

  @include mq(tablet-wide, min) {
    font-size: 16px;
    margin: 0 0 0 12px;
    padding: 6px 20px;
    &:hover {
      color: $main-color;
      background: $white;
    }
    .mcf-icon {
      font-size: 23px;
    }
  }
}

.mcf-button-fill-error {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-family: $main-font;
  font-weight: 600;
  font-size: 14px;
  color: $white;
  line-height: 1.25;
  border: 1px solid #F08292;
  background: linear-gradient(0deg, #F08292, #F08292), #F98C8C;
  min-width: 124px;
  @include box-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  border-radius: $button-radius;
  text-decoration: none;
  padding: 10px 20px;
  margin: 6px 0;
  @include transition($main-transition);
  cursor: pointer;

  @include mq(tablet-wide, min) {
    font-size: 16px;
    margin: 0 0 0 12px;
    padding: 6px 20px;
    &:hover {
      color: #F08292;
      background: transparent;
    }
  }
}

.mcf-input-wrapper {
  .mcf-input {
    width: 100%;
  }
}
</style>
