<template>
  <div>
    <div v-if="isRender"
         class="mcf-form-column mcf-exception-fill mcf-datetime-wrapper"
         :class="setPropertiesClass">
      <label v-show="showAttributeName">{{ item.attribute.name }}:</label>
      <div class="mcf-input-datetime-wrapper">
        <div>
          <input type="text"
                 class="mcf-input"
                 :placeholder="$t('field.year')"
                 v-model="form.year"
                 @focusin="focusInYear"
                 @focusout="focusOutYear"
                 @input="updateValue"/>
          <input type="text"
                 class="mcf-input"
                 :placeholder="$t('field.month')"
                 v-model="form.month "
                 @focusin="focusInMonth"
                 @focusout="focusOutMonth"
                 @input="updateValue"/>
          <input type="text"
                 class="mcf-input"
                 :placeholder="$t('field.day')"
                 v-model="form.day"
                 @focusin="focusInDay"
                 @focusout="focusOutDay"
                 @input="updateValue"/>
        </div>
        <div class="mcf-time-wrapper">
          <input type="text"
                 class="mcf-input"
                 :placeholder="$t('field.hours')"
                 v-model="form.hours"
                 @focusin="focusInHours"
                 @focusout="focusOutHours"
                 @input="updateValue"/>
          <span class="mcf-separator">:</span>
          <input type="text"
                 class="mcf-input"
                 :placeholder="$t('field.minutes')"
                 v-model="form.minutes"
                 @focusin="focusInMinutes"
                 @focusout="focusOutMinutes"
                 @input="updateValue"/>
        </div>
      </div>
      <div v-if="v$ && isShowError"
           class="mcf-validation-errors-wrapper">
        <div v-for="($error, i) in v$.$errors"
             :key="i"
             class="mcf-validation-errors">
          <div class="mcf-validation-error">
            {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import {useValidationErrors} from "@/composables/useValidationErrors";
import {DateFormatter} from "@/util/DateFormatter";
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, reactive, ref, toRef, unref} from "vue";
import {maxLength, minLength, numeric, required} from '@vuelidate/validators';
import {isDay, isHours, isMinutes, isMonth, isYear} from '../validators/CustomValidators'
import {useVuelidate} from "@vuelidate/core";
import {ActiveClasses} from "@/components/patientData/ActiveClasses";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const {getErrorMessage} = useValidationErrors()
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const autoFillData = toRef(props, 'autoFillData');
const isRender = ref(false)
const isFocusedOnYear = ref(false)
const isFocusedOnMonth = ref(false)
const isFocusedOnDay = ref(false)
const isFocusedOnHours = ref(false)
const isFocusedOnMinutes = ref(false)
const form = reactive({
  year: 0, // todo: discuss it
  month: 0,
  day: 0,
  hours: 0,
  minutes: 0
})
const rules = computed(() => ({
  form: {
    year: {
      required,
      numeric,
      maxLength: maxLength(4),
      isDate: isYear, ...(unref(item).attribute.currentDisplaySetting.required && {minLength: minLength(4)})
    },
    month: {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate: isMonth, ...(unref(item).attribute.currentDisplaySetting.required && {minLength: minLength(1)})
    },
    day: {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate: isDay, ...(unref(item).attribute.currentDisplaySetting.required && {minLength: minLength(1)})
    },
    hours: {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate: isHours, ...(unref(item).attribute.currentDisplaySetting.required && {minLength: minLength(1)})
    },
    minutes: {
      required,
      numeric,
      maxLength: maxLength(2),
      isDate: isMinutes, ...(unref(item).attribute.currentDisplaySetting.required && {minLength: minLength(1)})
    }
  }
}));
const v$ = useVuelidate(rules, {form});
const showAttributeName = computed(() => !unref(item).attribute.list);
const isShowError = computed(() => {
  return !(isFocusedOnYear.value ||
    isFocusedOnMonth.value ||
    isFocusedOnDay.value ||
    isFocusedOnHours.value ||
    isFocusedOnMinutes.value
  )
})
const setPropertiesClass = computed(() => {
  let className = ''
  if (v$.value.form.$invalid && isShowError.value) {
    className += ` ${ActiveClasses.ERROR_CLASS}`
  }
  if (form.day.toString().length &&
    form.month.toString().length &&
    form.year.toString().length &&
    form.hours.toString().length &&
    form.minutes.toString().length) {
    className += ` ${ActiveClasses.FILL_CLASS}`
  }
  return className
})

//methods
const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value),
    hours: DateFormatter.getFormatHours(value),
    minutes: DateFormatter.getFormatMinutes(value)
  }
}

function setFormValue() {
  item.value.validator = v$
  item.value.isReadyToModify = true

  if (unref(property)?.value != null) {
    Object.assign(form, setForm(new Date(unref(property).value)));
  } else if (unref(autoFillData)?.value != null) {
    setForm(new Date(unref(autoFillData).value))
  }

  isRender.value = true
}

function updateValue() {
  let updatedDate = new Date(form.year, form.month - 1, form.day, form.hours, form.minutes)
  property.value.value = updatedDate.getTime()
}

function focusInYear() {
  isFocusedOnYear.value = true
}

function focusOutYear() {
  isFocusedOnYear.value = false
}

function focusInMonth() {
  isFocusedOnMonth.value = true
}

function focusOutMonth() {
  isFocusedOnMonth.value = false
}

function focusInDay() {
  isFocusedOnDay.value = true
}

function focusOutDay() {
  isFocusedOnDay.value = false
}

function focusInHours() {
  isFocusedOnHours.value = true
}

function focusOutHours() {
  isFocusedOnHours.value = false
}

function focusInMinutes() {
  isFocusedOnMinutes.value = true
}

function focusOutMinutes() {
  isFocusedOnMinutes.value = false
}

//lifecycle
onMounted(() => {
  setFormValue()
})

</script>
