<template>
  <div class="mcf-content-text">
    <div class="mcf-content-item">
      <h2>{{ $t('aboutPage.impressum.company.title') }}</h2>
      <p>{{ $t('aboutPage.impressum.company.number') }}</p>
    </div>
    <div class="mcf-content-item">
      <h2>{{ $t('aboutPage.impressum.vat.title') }}</h2>
      <p>{{ $t('aboutPage.impressum.vat.number') }}</p>
    </div>
    <div class="mcf-content-item">
      <h2>{{ $t('aboutPage.impressum.legal.title') }}</h2>
      <p>{{ $t('aboutPage.impressum.legal.value') }}</p>
    </div>
    <div class="mcf-content-item">
      <h2>{{ $t('aboutPage.impressum.property_rights.title') }}</h2>
      <p>{{ $t('aboutPage.impressum.property_rights.text1') }}</p>
      <p v-html="$t('aboutPage.impressum.property_rights.text2')"></p>
    </div>
  </div>
</template>
