<template>
  <div class="mcf-input-wrapper"
       v-if="item.isReadyToModify"
       :class="{'mcf-error': v$.form.value.$invalid && item.isShowError}">
    <div class="mcf-label">{{ item.attribute.name }}</div>

    <div class="mcf-radio-wrapper"
         :class="{'mcf-error': v$.form.value.$invalid && item.isShowError}">
      <input type="radio"
             :id="getCheckboxId + '-true'"
             :value="true" v-model="form.value"
             @focusin="dontShowValidationError"
             @focusout="showValidationError"
             @input="updateRequiredValueTrue">
      <label :for="getCheckboxId + '-true'">{{ $t('general.yes') }}</label>
    </div>

    <div class="mcf-radio-wrapper"
         :class="{'mcf-error': v$.form.value.$invalid && item.isShowError}">
      <input type="radio"
             :id="getCheckboxId + '-false'"
             :value="false" v-model="form.value"
             @focusin="dontShowValidationError"
             @focusout="showValidationError"
             @input="updateRequiredValueFalse">
      <label :for="getCheckboxId + '-false'">{{ $t('general.no') }}</label>
    </div>
    <div v-if="v$ && item.isShowError"
         class="mcf-validation-errors-wrapper">
      <div v-for="($error, i) in v$.$errors"
           :key="i"
           class="mcf-validation-errors">
        <div class="mcf-validation-error">
          {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, reactive, toRef, unref} from "vue";
import {isNotNullBoolean} from "@/components/patientData/properties/validators/CustomValidators";
import {useVuelidate} from "@vuelidate/core";
import {useValidationErrors} from "@/composables/useValidationErrors";

//properties
const props = defineProps({
  item: ObjectData,
  property: Object,
})

//state
const item = toRef(props, 'item')
const property = toRef(props, 'property')
const form = reactive({
  value: null
})
const rules = computed(() => ({
  form: {
    value: {isNotNullBoolean: isNotNullBoolean()}
  }
}))
const v$ = useVuelidate(rules, {form});
const getCheckboxId = computed(() => `${unref(item).attribute.attributeType}-${unref(item).attribute.id}`);
const {getErrorMessage} = useValidationErrors()

//methods
function updateRequiredValueTrue() {
  form.value = true
  property.value.value = true
}

function updateRequiredValueFalse() {
  form.value = false
  property.value.value = false
}

function showValidationError() {
  item.value.isShowError = true;
}

function dontShowValidationError() {
  item.value.isShowError = false;
}

// Lifecycle
onMounted(() => {
  item.value.isReadyToModify = true;
  form.value = unref(property)?.value;
});
</script>
