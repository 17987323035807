<template>
  <div class="mcf-form-column mcf-exception-fill"
       :class="setPropertiesClass">
    <label v-show="showAttributeName"
           class="mcf-label">{{ item.attribute.name }}:</label>
    <div class="mcf-value-state">
          <textarea
            v-model="form.value"
            @input="updateValue"
            @focusin="dontShowValidationError"
            @focusout="showValidationError"
            class="mcf-textarea"/>
    </div>
    <div v-if="v$ && item.isShowError"
         class="mcf-validation-errors-wrapper">
      <div v-for="($error, i) in v$.$errors"
           :key="i"
           class="mcf-validation-errors">
        <div class="mcf-validation-error">
          {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, reactive, toRef, unref} from "vue";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useValidationErrors} from "@/composables/useValidationErrors";
import {ActiveClasses} from "@/components/patientData/ActiveClasses";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const {getErrorMessage} = useValidationErrors()
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const autoFillData = toRef(props, 'autoFillData');
const form = reactive({value: property.value?.value ?? ""});
const rules = computed(() => ({
  form: {
    value: {
      required: unref(item).attribute.currentDisplaySetting.required ? required : () => true
    }
  }
}))
const v$ = useVuelidate(rules, {form})
const showAttributeName = computed(() => !unref(item).attribute.list)
const setPropertiesClass = computed(() => {
  let className = ''
  if (v$.value.form.$invalid && unref(item).isShowError) {
    className += ` ${ActiveClasses.ERROR_CLASS}`
  }
  if (unref(property)?.value != null) {
    className += ` ${ActiveClasses.FILL_CLASS}`
  }
  if (unref(open)) {
    className += ` ${ActiveClasses.OPEN_CLASS}`
  }
  return className
})

//methods
function setFormValue() {
  item.value.validator = v$;
  item.value.isReadyToModify = true;

  if (unref(property)?.value == null) {
    if (unref(autoFillData)?.value != null) {
      property.value.value = unref(autoFillData).value
    } else {
      property.value.value = ''
    }
  }
  form.value = unref(property).value
}

function updateValue() {
  property.value.value = form.value;
}

function showValidationError() {
  item.value.isShowError = true;
}

function dontShowValidationError() {
  item.value.isShowError = false;
}

//lifecycle
onMounted(() => {
  setFormValue()
})
</script>
