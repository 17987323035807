<template>
  <div class="mcf-form-row mcf-checkbox-wrapper mcf-completed">
    <input
      type="checkbox"
      v-model="getPropertyValue"
      :id="getCheckboxId"
      class="mcf-input"
      disabled="disabled"/>
    <label :for="getCheckboxId"
           class="mcf-label mcf-label-checkbox">
      {{ item.attribute.name }}
      <em class="mcf-icon mcf-icon-check"></em>
    </label>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, toRef, unref} from "vue";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object
});

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const getCheckboxId = computed(() => `${item.value.attribute.attributeType}-${item.value.attribute.id}`);
const getPropertyValue = computed(() => {
  if (!unref(property)?.value) {
    return false
  }
  return unref(property).value;
});
</script>

<style scoped lang="scss">
.mcf-form {
  &-row {
    &.mcf-checkbox-wrapper {
      font-weight: 400;
    }

    .mcf-value {
      color: $service-color;
    }
  }
}
</style>
