<template>
  <div>
    <div class="mcf-tabs">
      <ul>
        <li v-for="tab in tabs" :key="tab.link" :class="{ 'mcf-active': tab.link === getNormalizedPath(route.path) }">
          <router-link :to="tab.link">{{ tab.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="mcf-tabs-details">
      <slot/>
    </div>
  </div>
</template>

<script setup>
import {ref, provide} from 'vue';
import {useRoute} from 'vue-router';
import {useI18n} from "vue-i18n";

//state
const {availableLocales} = useI18n();
const route = useRoute();
const tabs = ref([]);

provide('tabs', {tabs});

//methods
function getNormalizedPath(path) {
  return availableLocales.reduce((acc, locale) => {
    const regex = new RegExp(`^/${locale}`);
    return acc.replace(regex, '');
  }, path);
}
</script>

<style lang="scss">
.mcf-tab-content {
  padding: 92px 0 0;

  h1 {
    font-family: $main-font;
    font-weight: 700;
    font-size: 50px;
    color: $text-color;
    line-height: 1.35;
    margin: 0 0 29px;
  }

  .mcf-tabs > ul {
    @include inline-flex;
    @include align-items(flex-end);
    width: 100%;
    margin: 0 0 35px;
    border-bottom: 1px solid #BAEAFB;

    > li {
      margin: 0;
      padding: 0 15px 0 0;
      width: 33.33%;

      a, span {
        position: relative;
        display: inline-block;
        font-size: 18px;
        font-family: $main-font;
        font-weight: 500;
        color: $text-color;
        padding: 0 0 10px;

        &::before {
          @include pseudo();
          bottom: -2px;
          left: 0;
          height: 3px;
          width: 0;
          @include transition($main-transition);
          pointer-events: none;
        }

        @include mq(tablet-wide, min) {
          &:hover {
            &:before {
              background: $service-color;
              width: 110%;
            }
          }
        }
      }

      @include mq(tablet, min) {
        padding: 0;
        a, span {
          font-size: 22px;
        }
      }

      &.mcf-active {
        a, span {
          color: $service-color;
          font-weight: 600;

          &:before {
            width: calc(100% + 15px);
            background: $service-color;
            @include mq(tablet, min) {
              width: 110%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mcf-static-wrap {
    &.mcf-about-static {
      .mcf-tab-content {
        .mcf-tabs {
          > ul {
            @include justify-content(space-between);

            > li {
              width: auto;
            }
          }
        }
      }
    }

    &.mcf-static-license {
      .mcf-tab-content {
        .mcf-tabs {
          > ul {

            > li {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
