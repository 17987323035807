<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label">{{ item.attribute.name }}</div>
    <div v-if="filename !== '-'">
      <a href="#"
         class="mcf-value"
         v-text="filename"
         :class="{ 'mcf-with-name': filename }"
         @click.prevent="downloadFile"></a>
      <div v-if="isImage">
        <img :src="imgUrl"/>
      </div>
    </div>
    <div v-else>
      <div class="mcf-value">{{ filename }}</div>
    </div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, ref, toRef, unref} from "vue";
import {uploadedFileApi} from "@/api/uploadedFiles";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object, // vue warn about property is Object (not a Property)
});

//state
const filename = ref(null)
const imgUrl = ref(null)
const property = toRef(props, 'property');
const downloadFile = computed(() => {
  if (unref(property)?.id) {
    uploadedFileApi.downloadFile(unref(property).id, filename.value)
  }
  return null;
})
const isImage = computed(() => {
  if (filename.value) {
    const lowerName = filename.value.toLowerCase()
    const extensions = ['.jpg', '.jpeg', '.png', '.gif']

    for (const extension of extensions) {
      if (lowerName.endsWith(extension)) {
        return true
      }
    }
  }
  return false
})

//methods
async function fetchFileInfo() {
  if (unref(property)?.id) {
    const resp = await uploadedFileApi.getFileInfo(unref(property).id)
    const fileInfo = resp.data
    if (fileInfo) {
      filename.value = fileInfo.name
    }
  }
  if (!filename.value) {
    filename.value = '-'
  }
}

async function fetchImage() {
  if (!isImage.value) {
    return
  }
  imgUrl.value = await uploadedFileApi.fetchFileInBase64(unref(property).id, filename.value)
}

//lifecycle
onMounted(async () => {
  await fetchFileInfo()
  await fetchImage()
})
</script>


<style scoped lang="scss">
.mcf-form {
  &-row {
    a {
      color: $link-color;
      @include transition($main-transition);
    }

    @include mq(tablet-wide, min) {
      a:hover {
        color: $link-hover-color;
      }
    }
  }
}
</style>
