<template>
  <div class="mcf-textarea-wrapper">
    <label v-show="showAttributeName"
           class="mcf-label">{{ item.attribute.name }}:</label>
    <input
      v-model="property.value"
      class="mcf-input"/>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, toRef, unref} from "vue";

//properties
const props = defineProps({
  item: ObjectData,
  property: Object,
})

//state
const item = toRef(props, 'item')
const property = toRef(props, 'property')
const showAttributeName = computed(() => !unref(item).attribute.list)

//methods
function prepareItemAndProperty() {
  item.value.isReadyToModify = true

  if (unref(property)?.value == null) {
    property.value.value = ''
  }
}

// lifecycle
onMounted(() => {
  prepareItemAndProperty()
})
</script>
