import {defineStore} from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    isActiveMobMenu: false,
    isPageNotFound: false
  }),
  actions: {
    setMobMenuStatus(status) {
      this.isActiveMobMenu = status
    },
    setPageNotFound(status) {
      this.isPageNotFound = status
    }
  },
  getters: {
    getMobMenuStatus(state) {
      return state.isActiveMobMenu
    },
  }
})
