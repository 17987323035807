<template>
  <div></div>
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'MedicoFolder',
  meta: [],
});
</script>
