<template>
  <a href="#"
     class="mcf-header-burger"
     @click.prevent="onBurgerClick">
    <span></span>
    <span></span>
    <span></span>
  </a>
</template>

<script setup>
import {useAppStore} from "@/stores/useAppStore";

//state
const store = useAppStore()

//methods
function onBurgerClick() {
  const className = 'mcf-active'
  if (store.getMobMenuStatus) {
    document.body.classList.remove(className)
    store.setMobMenuStatus(false)
  } else {
    document.body.classList.add(className)
    store.setMobMenuStatus(true)
  }
}
</script>

<style scoped lang="scss">
.mcf-header {
  &-burger {
    display: block;
    position: absolute;
    top: 30px;
    right: 16px;
    height: 18px;
    width: 24px;
    @include transition($navigation-transition);

    span {
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 auto;
      @include transform(rotate(0));
      @include transition(.4s all cubic-bezier(0.175, 0.885, 0.32, 1.275));
      height: 2px;
      width: 100%;
      background: $white;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 44%;
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }

    @include mq(tablet-wide, min) {
      display: none;
    }
  }
}

.mcf-active {
  .mcf-header-burger {
    span {
      &:nth-child(1) {
        top: 7px;
        @include transform(rotate(-45deg));
      }

      &:nth-child(2) {
        width: 0;
      }

      &:nth-child(3) {
        bottom: 9px;
        @include transform(rotate(45deg));
      }
    }
  }
}

</style>
