<template>
  <div id="app">
    <div
      class="mcf-app-wrapper"
      :class="{'mcf-active': store.getMobMenuStatus, 'mcf-embed-page': isEmbedUrl}">
      <head-config/>
      <navigation-menu-mob v-if="!isEmbedUrl"/>
      <div class="mcf-mob-content-wrap">
        <no-search-header v-if="!isEmbedUrl"/>
        <div class="mcf-app-content-wrapper">
          <container-content/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, watch} from 'vue';
import {AuthService} from "@/util/authService";
import HeadConfig from "@/components/HeadConfig.vue";
import {useRoute, useRouter} from "vue-router";
import NavigationMenuMob from "@/components/navigation/NavigationMenuMob.vue";
import NoSearchHeader from "@/components/header/NoSearchHeader.vue";
import ContainerContent from "@/components/ContainerContent.vue";
import {useI18n} from "vue-i18n";
import {useAppStore} from "@/stores/useAppStore";

//state
const route = useRoute();
const router = useRouter();
const isEmbedUrl = computed(() => route.query?.displayType === 'embed');
const {locale} = useI18n();
const store = useAppStore();

//watchers
watch(locale, (newLocale, oldLocale) => {
  if (oldLocale !== newLocale) {
    document.body.classList.remove('mcf-active')
    store.setMobMenuStatus(false);
    router.changeLocale(newLocale)
  }
})

// Listen for store changes
if (process.env.NODE_ENV == 'development') {
  store.$subscribe((mutation, state) => {
    console.log('Mutation type:', mutation)
    console.log('New state:', state)
  })
}

//lifecycle
onMounted(async () => {
  const authService = AuthService.getInstance()
  await authService.setAuthParamsFromUrl();
  await authService.getAuthorization();
});
</script>

<style lang="scss">
@use "@/assets/style/style.scss" as *;
.mcf-app-wrapper {
  &.mcf-embed-page {
    .mcf-app-content-wrapper {
      padding: 0;
    }

    .mcf-content-wrapper {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0;
      @include mq(tablet-wide, min) {
        margin-top: 40px;
        margin-bottom: 40px;
        border-radius: 8px;
      }
    }
  }
}

.mcf-app-content-wrapper {
  padding: 80px 0 0;
  @include mq(tablet-wide, min) {
    padding: 0;
  }
}

@media print {
  .mcf-app-wrapper {
    .mcf-content-wrapper {
      @include box-shadow(none);
      margin: 0;
      padding: 0;
    }

    .mcf-header-name,
    .mcf-header-sign a,
    .mcf-header-burger,
    .mcf-copy-button,
    .mcf-info-box-fixed,
    #mcf-lang-selector,
    .mcf-display-mode-wrapper,
    .mcf-notification,
    .mcf-button-wrapper,
    .mcf-object-tag-list,
    .mcf-object-actions {
      display: none !important;
    }

    .mcf-header-logo-img {
      color: $text-color;
    }

    .mcf-button-fill, .mcf-button-transparent {
      @include box-shadow(none);
    }

    .mcf-form-copy-value .mcf-link {
      max-width: 100% !important;
    }
  }
}
</style>
