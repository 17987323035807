<template>
  <div v-if="isActive">
    <slot></slot>
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {computed, inject, onMounted} from "vue";

//properties
const props = defineProps({
  name: { type: String, required: true },
  link: { type: String, required: true }
});

// State
const route = useRoute();
const isActive = computed(() => route.path === props.link);
const tabs = inject('tabs');

//lifecycle
onMounted(() => {
  tabs.tabs.value.push({ name: props.name, link: props.link });
});
</script>
