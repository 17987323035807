<template>
  <div class="mcf-content-text">
    <div v-html="privacyPolicy"></div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {ContentDataApi} from "@/api/contentData";

//state
const privacyPolicy = ref("");
privacyPolicy.value = await ContentDataApi.getInstance().fetchPrivacyPolicy()

//lifecycle
onMounted(() => {
  window.scrollTo(0, 0)
})
</script>

<style scoped lang="scss">
ul {
  list-style: disc;
  margin: 0 0 35px;
  padding: 0 0 0 40px;
}
</style>
