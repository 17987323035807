<template>
  <div v-if="item.isReadyToModify"
       class="mcf-form-column mcf-exception-fill"
       :class="setPropertiesClass">
    <div v-show="showAttributeName"
         class="mcf-label">{{ item.attribute.name }}:
    </div>
    <div class="mcf-value-state">
      <div class="mcf-select"
           @blur="open = false">
        <div class="mcf-selected"
             ref="selectRef"
             :class="{'mcf-open': open}"
             @click="open = !open">
          {{ property.value }}
        </div>
        <div class="mcf-items"
             :class="{'mcf-select-hide': !open}">
          <div class="mcf-item"
               v-for="(option, i) of enumItems"
               :key="i"
               @click="updateValue(option)"
               @focusin="dontShowValidationError"
               @focusout="showValidationError">
            {{ option.name }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="v$ && item.isShowError"
         class="mcf-validation-errors-wrapper">
      <div v-for="($error, i) in v$.$errors"
           :key="i"
           class="mcf-validation-errors">
        <div class="mcf-validation-error">
          {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref, toRef, unref, useTemplateRef} from "vue";
import ObjectData from "@/components/patientData/ObjectData";
import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {onClickOutside} from "@vueuse/core";
import EnumeratedItemList from "@/models/patientData/EnumeratedItemList";
import {useValidationErrors} from "@/composables/useValidationErrors";
import {ActiveClasses} from "@/components/patientData/ActiveClasses";

//properties
const props = defineProps({
  item: ObjectData,
  property: Object,
})

//state
const selectRef = useTemplateRef('selectRef');
const item = toRef(props, 'item')
const property = toRef(props, 'property')
const enumItems = ref([])
const open = ref(false)
const form = reactive({value: unref(property)?.value ?? ""});
const rules = computed(() => ({
  form: {
    value: {
      required: unref(item).attribute.currentDisplaySetting.required ? required : (d) => true
    }
  }
}))
const v$ = useVuelidate(rules, {form})
const showAttributeName = computed(() => !item.value.attribute.list)
const setPropertiesClass = computed(() => {
  let className = ''
  if (v$.value.form.$invalid && unref(item).isShowError) {
    className += ` ${ActiveClasses.ERROR_CLASS}`
  }
  if (unref(property)?.value != null) {
    className += ` ${ActiveClasses.FILL_CLASS}`
  }
  if (unref(open)) {
    className += ` ${ActiveClasses.OPEN_CLASS}`
  }
  return className
})
const {getErrorMessage} = useValidationErrors()

//methods
async function prepareItemAndProperty() {
  item.value.validator = v$
  item.value.isReadyToModify = true
  const selectItems = await EnumeratedItemList.getEnumeratedItemByEnumeratedValuesId(unref(item).attribute.enumeratedValuesId)
  if (!selectItems || !selectItems.models) {
    return
  }
  selectItems.models.forEach(item => {
    enumItems.value.push({
      value: item.value,
      name: item.name,
      id: item.id
    })
  })
}

function updateValue(option) {
  open.value = false
  property.value.value = option.value
}

function showValidationError() {
  item.value.isShowError = true
}

function dontShowValidationError() {
  item.value.isShowError = false
}

function onClose() {
  if (open.value) {
    open.value = false
  }
}

onClickOutside(selectRef, () => onClose())

// lifecycle
onMounted(async () => {
  await prepareItemAndProperty()
})
</script>
