<template>
  <div class="mcf-form-row">
    <div class="mcf-label" v-html="item.attribute.name"/>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {onMounted, toRef} from "vue";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const item = toRef(props, 'item');

//lifecycle
onMounted(() => {
  item.value.isReadyToModify = true
})
</script>
