<template>
  <div class="mcf-container">
    <div class="mcf-content-wrapper">
      <spinner-element :loading="!!isLoading"/>

      <div v-if="object != null && !object.completed && notificationHeader"
           v-show="!isLoading"
           ref="notificationEdit"
           class="mcf-notification mcf-notification-top"
      >
        <i class="mcf-icon mcf-icon-exclamation-mark"></i>
        {{ notificationHeader }}
      </div>

      <object-renderer
        v-if="Boolean(object)"
        :object="object"
        :render-mode="renderMode"
        v-on:is-rendered-object="hideSpinner"/>
    </div>
  </div>
</template>

<script setup>
import SpinnerElement from "@/components/SpinnerElement.vue";
import {useSpinner} from "@/composables/useSpinner";
import {Obj} from "@/models/patientData/Object";
import ObjectRenderer from "@/components/patientData/objects/ObjectRenderer.vue";
import {computed, onMounted, ref, unref} from "vue";
import RenderMode from "@/components/patientData/RenderMode";
import {useRoute, useRouter} from "vue-router";
import {useAppStore} from "@/stores/useAppStore";
import {useI18n} from "vue-i18n";

//state
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const {t} = useI18n()
const store = useAppStore();
const route = useRoute()
const router = useRouter()
const object = ref(null)
const activeDisplaySetting = ref(null)
const renderMode = RenderMode.MODIFY_ITEM
const isEmbedUrl = computed(() => route.query?.displayType === 'embed')
const notificationHeader = computed(() => {
  if (unref(activeDisplaySetting)?.notificationHeader) {
    if (!unref(activeDisplaySetting)?.notificationHeader.trim().length) {
      return "";
    }
    return unref(activeDisplaySetting)?.notificationHeader
  }
  return t("object.display.edit.notification")
})

//methods
function objectShowLink() {
  if (isEmbedUrl.value) {
    return '/object/show/' + object.value.id + '?displayType=embed'
  } else {
    return '/object/show/' + object.value.id
  }
}

//lifecycle
onMounted(async () => {
  showSpinner()
  if (route.params != null && route.params.id != null) {
    let obj = new Obj({id: route.params.id})
    await obj.fetch()
      .catch((data) => {
        switch (data.error.response.status) {
          case 404:
            store.setPageNotFound(true)
            return
        }
      })
    if (obj.isNested) {
      store.setPageNotFound(true)
    }
    object.value = obj
    if (obj.completed) {
      await router.push({path: objectShowLink()})
    }

    const objectDisplaySettings = await obj.getDisplaySettings()
    if (objectDisplaySettings.length > 0) {
      activeDisplaySetting.value = objectDisplaySettings.find(it => it.displayPage === "EDIT");
    }
  } else {
    store.setPageNotFound(true)
  }
})
</script>
