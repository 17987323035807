<template>
  <div class="mcf-input-wrapper mcf-checkbox-wrapper">
    <input type="checkbox"
           v-model="property.value"
           :id="getCheckboxId"
           class="mcf-input"/>
    <label :for="getCheckboxId"
           class="mcf-label">
      {{ item.attribute.name }}
      <em class="mcf-icon mcf-icon-check"></em>
    </label>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, toRef, unref} from "vue";

//properties
const props = defineProps({
  item: ObjectData,
  property: Object,
})

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const getCheckboxId = computed(() => `${unref(item).attribute.attributeType}-${unref(item).attribute.id}`)

//methods
function prepareItemAndProperty() {
  item.value.isReadyToModify = true;

  if (unref(property)?.value == null) {
    property.value.value = false
  }
}

//lifecycle
onMounted(() => {
  prepareItemAndProperty()
})
</script>
