<template>
  <div class="mcf-form-column mcf-completed">
    <div class="mcf-label">{{ item.attribute.name }}</div>
    <div class="mcf-radio-wrapper">
      <input type="radio"
             :id="getCheckboxId + '-true'"
             disabled="disabled"
             :value="true"
             v-model="getPropertyValue">
      <label :for="getCheckboxId + '-true'"
             class="mcf-label mcf-label-radio">{{ $t('general.yes') }}</label>
    </div>
    <div class="mcf-radio-wrapper">
      <input type="radio"
             :id="getCheckboxId + '-false'"
             disabled="disabled"
             :value="false"
             v-model="getPropertyValue">
      <label :for="getCheckboxId + '-false'"
             class="mcf-label mcf-label-radio">{{ $t('general.no') }}</label>
    </div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, toRef, unref} from "vue";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
});

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const getCheckboxId = computed(() => `${unref(item).attribute.attributeType}-${unref(item).attribute.id}`);
const getPropertyValue = computed(() => unref(property)?.value);
</script>
