import {Model} from 'vue-mc-vue3'
import {Ajax} from "@/util/ajaxLoader";

export default class AttributeCategory extends Model {

  defaults() {
    return {
      id: null,
      code: '',
    }
  }

  mutations() {
    return {
      id: (id) => Number(id) || null,
      name: String,
    }
  }

  async fetch(options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders()
    options.headers = {...options.headers, ...defaultHeaders}
    return super.fetch(options)
  }

  routes() {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/attributeCategory/{id}`,
    }
  }
}
