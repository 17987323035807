<template>
  <div>
    <div v-for="(item, i) in renderData" :key="i">
      <div v-show="isShowItem(item)">
        <div v-if="allowShowAsList(item)"
             class="mcf-list-wrap">
          <div class="mcf-label">{{ item.attribute.name }}</div>
          <div class="mcf-list-row"
               v-for="property in item.properties"
               :key="property.index">
            <component
              :is="computePropertyTag(item)"
              :item="item"
              :property="property"/>
          </div>
        </div>
        <div v-else>
          <component
            :is="computePropertyTag(item)"
            :item="item"
            :property="item.properties[0]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AttributeType from "@/components/patientData/AttributeTypes";
import EnumShow from "@/components/patientData/properties/show/EnumShow.vue";
import TextShow from "@/components/patientData/properties/show/TextShow.vue";
import BooleanShow from "@/components/patientData/properties/show/BooleanShow.vue";
import NotNullBooleanShow from "@/components/patientData/properties/show/NotNullBooleanShow.vue";
import LinkShow from "@/components/patientData/properties/show/LinkShow.vue";
import NumericShow from "@/components/patientData/properties/show/NumericShow.vue";
import DateShow from "@/components/patientData/properties/show/DateShow.vue";
import DateTimeShow from "@/components/patientData/properties/show/DateTimeShow.vue";
import ImmutableTextShow from "@/components/patientData/properties/show/ImmutableTextShow.vue";
import NestedObjectShow from "@/components/patientData/properties/show/NestedObjectShow.vue";
import UploadedShow from "@/components/patientData/properties/show/UploadedShow.vue";
import LongTextShow from "@/components/patientData/properties/show/LongTextShow.vue";

// Props
const props = defineProps({
  renderData: Array
});

//state
const componentsMap = {
  'enum': EnumShow,
  'text': TextShow,
  'long-text': LongTextShow,
  'boolean': BooleanShow,
  'not-null-boolean': NotNullBooleanShow,
  'link': LinkShow,
  'numeric': NumericShow,
  'date': DateShow,
  'date-time': DateTimeShow,
  'immutable-text': ImmutableTextShow,
  'uploaded': UploadedShow,
  'nested-object': NestedObjectShow
}

//methods
function allowShowAsList(item) {
  let attributeType = item.attribute.attributeType
  return item.attribute.list
    && attributeType !== AttributeType.BOOLEAN
    && attributeType !== AttributeType.NOT_NULL_BOOLEAN
    && attributeType !== AttributeType.IMMUTABLE_TEXT
    && attributeType !== AttributeType.UPLOADED
    && attributeType !== AttributeType.NESTED_OBJECT
}

function computePropertyTag(item) {
  return componentsMap[AttributeType.resolveTypePrefix(item.attribute.attributeType)]
}

function isShowItem(item) {
  return item.attribute.currentDisplaySetting.showInForm
}
</script>
