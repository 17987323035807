import axios from 'axios'
import Cookies from '@/util/Cookies'
import {i18n} from "@/i18n";

class AjaxLoader {
  constructor() {
    this.axios = axios
    this.axios.defaults.withCredentials = true
  }

  async sendGet(url, params = {}, headers = {}, options = {}) {
    let builtHeaders = await this.buildHeaders(headers)
    return this.handleResponse(
      this.axios.get(this.appendBaseUrl(url), {params: params, headers: builtHeaders, ...options})
    )
  }

  async get(url, config) {
    return this.axios.get(url, config)
  }

  async sendPost(url, params, headers = {}, options = {}) {
    let builtHeaders = await this.buildHeaders(headers)
    return this.handleResponse(
      this.axios.post(this.appendBaseUrl(url), params, {headers: builtHeaders, ...options})
    )
  }

  async post(url, params, config) {
    return this.axios.post(url, params, config)
  }

  async sendPut(url, params, headers = {}) {
    let builtHeaders = await this.buildHeaders(headers)
    return this.handleResponse(
      this.axios.put(this.appendBaseUrl(url), params, {headers: builtHeaders})
    )
  }

  async sendDelete(url, params, headers = {}) {
    let builtHeaders = await this.buildHeaders(headers)
    return this.handleResponse(
      this.axios.delete(this.appendBaseUrl(url), {headers: builtHeaders, data: params})
    )
  }

  async buildHeaders(customHeaders) {
    const headers = await Ajax.getDefaultHeaders()
    const mergedHeaders = {...headers, ...customHeaders}
    return Object.fromEntries(Object.entries(mergedHeaders).filter(([key, value]) => value))
  }

  handleResponse(resp) {
    return resp
      .then((response) => {
        return this.preHandlingSuccessfulResponse(response)
      })
      .catch((error) => {
        return this.preHandlingFailedResponse(error)
      })
  }

  preHandlingSuccessfulResponse(response) {
    return response
  }

  preHandlingFailedResponse(error) {
    throw error
  }

  appendBaseUrl(url) {
    if (url.match('^https?://')) {
      return url
    } else {
      return `${process.env.VUE_APP_HEALTHBANK_HOST}${url}`
    }
  }

}

let ajax = null

export const Ajax = {
  getInstance() {
    if (ajax == null) {
      ajax = new AjaxLoader()
    }
    return ajax
  },
  async getDefaultHeaders() {
    let headers = {...this.buildLocaleHeader(i18n.global.locale.value)}

    let accessToken = Cookies.getAccessToken()
    if (Boolean(accessToken)) {
      headers = {...headers, ...this.buildAuthHeader(accessToken)}
    }
    return headers
  },
  buildAuthHeader(accessToken) {
    return {'Authorization': 'Bearer ' + accessToken}
  },
  buildLocaleHeader(locale) {
    return {'X-Api-Locale': locale}
  }
}
