import {Model} from 'vue-mc-vue3'
import TypeCategory from "@/models/patientData/TypeCategory";
import AttributeList from "@/models/patientData/AttributeList";
import Attribute from "@/models/patientData/Attribute";
import {Ajax} from "@/util/ajaxLoader";

export default class Type extends Model {

  defaults() {
    return {
      id: null,
      name: '',
      categoryId: null,
      signRequired: false
    }
  }

  mutations() {
    return {
      id: (id) => Number(id) || null,
      name: String,
      categoryId: (id) => Number(id) || null
    }
  }

  getCategory() {
    if (this.categoryId != null) {
      let category = new TypeCategory({id: this.categoryId})
      return category.fetch().then(() => category)
    }
    return Promise.reject()
  }

  getAttributes() {
    return Ajax
      .getInstance()
      .sendGet(`${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/attribute/type/${this.id}`)
      .then((response) => {
        if (response.data == null) {
          return []
        }
        return new AttributeList(response.data.map(attr => new Attribute(attr)))
      })
      .catch((error) => {
        // todo test
        console.log('Failed try to sendGet type attributes!')
        return null
      })
  }

  async fetch(options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders()
    options.headers = {...options.headers, ...defaultHeaders}
    return super.fetch(options)
  }

  routes() {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/type/{id}`,
    }
  }
}
