import {createApp} from 'vue'
import App from './App.vue';
import {createPinia} from "pinia";
import {createHead} from "@vueuse/head";
import {setupRouter} from "@/router";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {i18n} from "@/i18n";

// Create Pinia store instance
const pinia = createPinia();

// Create Vue app instance
const app = createApp(App);

// Initialize Head for managing head elements
const head = createHead();

// Create and configure the store
app.use(pinia);

app.config.devtools = process.env.NODE_ENV !== 'production';

// Use the rest of the plugins and mount the app
app.use(head);
app.use(i18n);

//app add toast
app.config.globalProperties.$toast = useToast();

const router = setupRouter(app);
app.use(router);

router
  .isReady()
  .then(() => app.mount('#app'));
