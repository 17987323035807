<template>
  <div class="mcf-form-column mcf-completed">
    <div v-show="showAttributeName"
         class="mcf-label">{{ item.attribute.name }}
    </div>
    <div class="mcf-value">{{ getPropertyValue }}</div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, toRef, unref} from "vue";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,// vue warn about property is Object (not a Property)
});

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const showAttributeName = computed(() => !unref(item).attribute.list)
const executePropertyValue = computed(() => {
  if (!unref(property)?.value) {
    return '-'
  }
  return unref(property).value
})
const getPropertyValue = computed(() => {
  if (unref(item).attribute.list) {
    return `${unref(property).index + 1}. ${executePropertyValue.value}`
  }
  return executePropertyValue.value
});
</script>
