<template>
  <div class="mcf-form-column mcf-completed">
    <div v-show="showAttributeName"
         class="mcf-label">
      {{ item.attribute.name }}
    </div>
    <div v-if="!getPropertyValue.match(/[\d]+\. -/g)"
         class="mcf-value mcf-copy-value">
      <a :href="getPropertyValue"
         :title="getPropertyValue"
         ref="linkRef"
         target="_blank"
         class="mcf-link">{{ getPropertyValue }}</a>
      <a v-if="getPropertyValue"
         href="#"
         @click.prevent="selectText"
         class="mcf-copy-button">{{ $t('general.copy') }}
      </a>
    </div>
    <div class="mcf-value" v-else>
      {{ getPropertyValue }}
    </div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, toRef, unref, useTemplateRef} from "vue";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
});

//state
const linkRef = useTemplateRef('linkRef')
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const showAttributeName = computed(() => !unref(item).attribute.list)
const executePropertyValue = computed(() => {
  if (!unref(property)?.value) {
    return '-'
  }
  return unref(property).value
})
const getPropertyValue = computed(() => {
  if (unref(item).attribute.list) {
    return `${unref(property).index + 1}. ${executePropertyValue.value}`
  }
  return executePropertyValue.value
});

//methods
function selectText() {
  if (document.selection) {// IE
    let range = document.body.createTextRange()
    range.moveToElementText(linkRef.value)
    range.select()
  } else if (window.getSelection) {
    let range = document.createRange()
    range.selectNode(linkRef.value)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
  }
  document.execCommand('copy')
}
</script>

<style scoped lang="scss">
.mcf-copy-value {
  @include flexbox;
  @include align-items(center);

  .mcf-copy-button {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-shrink(0);
    font-family: $main-font;
    font-weight: 600;
    font-size: 14px;
    color: $white;
    border: 1px solid $main-color;
    background: $main-color;
    @include box-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
    border-radius: $main-radius;
    padding: 5px 10px;
    margin: 0 0 0 3px;
    @include transition($main-transition);
    cursor: pointer;
  }

  .mcf-link {
    color: $link-color;
    max-width: 185px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include transition($main-transition);
  }

  @include mq(tablet-wide, min) {
    .mcf-link {
      max-width: 215px;

      &:hover {
        color: $link-hover-color;
      }
    }
    .mcf-copy-button:hover {
      background: $white;
      color: $main-color;
    }
  }
}
</style>
