<template>
  <div class="mcf-static-wrap mcf-about-static">
    <div class="mcf-container">
      <div class="mcf-tab-content">
        <h1>{{ tabName }}</h1>
        <div class="mcf-flex-row">
          <div class="mcf-flex-l">
            <Tabs>
              <h1 class="mcf-static-title-mob">{{ tabName }}</h1>
              <div class="mcf-static-img-mob">
                <img src="@/assets/images/static/doctors-about@2x.png"/>
              </div>
              <Tab
                :name="$t('aboutPage.tabs.about')"
                :link="'/content/about'"/>
              <Tab
                :name="$t('aboutPage.tabs.impressum')"
                :link="'/content/about/impressum'"/>
              <Tab
                :name="$t('aboutPage.tabs.contacts')"
                :link="'/content/about/contacts'"/>
            </Tabs>
            <router-view/>
          </div>
          <div class="mcf-flex-r">
            <img src="@/assets/images/static/doctors-about@2x.png"/>
          </div>
        </div>
      </div>
    </div>
    <footer-element/>
  </div>
</template>

<script setup>
import {useHead} from '@vueuse/head';
import Tabs from "@/components/tabs/Tabs.vue";
import Tab from "@/components/tabs/Tab.vue";
import {computed, onMounted, ref, unref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import FooterElement from "@/components/footer/FooterElement.vue";

//state
const route = useRoute();
const {t} = useI18n();
const activeTab = ref(route.path);
const tabName = computed(() => t(`aboutPage.tabs.${getLastWord(activeTab.value)}`));
const pageTitle = computed(() => t('page.title.MedicoFolder', [t(`aboutPage.tabs.${getLastWord(activeTab.value)}`)]));

// Watchers
watch(() => route.path, (newPath) => {
  activeTab.value = newPath;
});

//methods
function getLastWord(path) {
  return path.split('/').filter(Boolean).pop();
}

//lifecycle
onMounted(() => {
  window.scrollTo(0, 0);
});

//meta
useHead({
  title: computed(() => unref(pageTitle)),
});
</script>

<style scoped lang="scss">
.mcf-static-wrap {
  background: #fff;
  min-height: calc(100vh - 80px);
}

.mcf-content-text {
  &,
  :deep(p) {
    font-size: 18px;
    color: $static-color;
    line-height: 1.9;
    font-family: $main-font;
    font-weight: 400;
  }


  & {
    margin: 0 0 200px;
  }

  &:deep(p) {
    margin: 0 0 35px;
  }

  .mcf-static-small {
    font-size: 15px;
    font-weight: 500;
    color: #B3B7BA;
  }

  &:deep(.mcf-static-small) {
    font-size: 15px;
    font-weight: 500;
    color: #B3B7BA;
  }

  &:deep(a:not(.mcf-btn)) {
    color: $link-color;
    @include mq(tablet-wide, min) {
      &:hover {
        color: $link-hover-color;
      }
    }
  }

  &:deep(h2),
  &:deep(.mcf-content-h2) {
    font-family: $main-font;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 12px;
    line-height: 1.1;
  }

  ul {
    margin: 0 0 35px;
    padding: 0;

    li {
      margin: 0 0 28px;
      position: relative;
      list-style-type: none;
      padding: 0 0 0 20px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 12px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $link-color;
      }
    }
  }
}

.mcf-static-wrap {
  .mcf-flex-row {
    @include flexbox;
  }

  .mcf-flex-l {
    width: 100%;
    @include mq(tablet-wide, min) {
      width: 49%;
    }
  }

  .mcf-flex-r {
    display: none;
    width: 51%;
    padding: 0 0 0 6%;
    margin: -82px 0 0;
    text-align: right;

    img {
      width: 100%;
    }

    @include mq(tablet-wide, min) {
      display: block;
    }
  }

  .mcf-static-img-mob {
    display: block;
    max-width: 263px;
    margin: 0 auto 24px auto;
    @include mq(tablet-wide, min) {
      display: none;
    }
  }

  h1.mcf-static-title-mob {
    display: none;
  }
}

@include mq(tablet-wide, max) {
  .mcf-static-wrap {
    .mcf-tab-content {
      width: 100%;
      padding: 40px 24px 50px;

      h1 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
      }
    }

    .mcf-content-text {
      &, p {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 20px;
      }

      ul li {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 7px;
      }
    }

    :deep(.mcf-content-text) {
      p {
        font-size: 14px;
        line-height: 2.29;
        margin: 0 0 20px;
      }

      h2 {
        font-size: 16px;
        margin: 0 0 10px;
      }
    }

    &.mcf-about-static h1 {
      display: none;

      &.mcf-static-title-mob {
        display: block;
      }
    }
  }
}
</style>
