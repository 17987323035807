import {Model} from 'vue-mc-vue3'
import Attribute from "@/models/patientData/Attribute";
import {Ajax} from "@/util/ajaxLoader";

export default class AttributeDisplaySetting extends Model {

  defaults() {
    return {
      id: null,
      attributeId: null,
      orderIndex: null,
      showInList: false,
      showInForm: true,
      required: false
    }
  }

  mutations() {
    return {
      id: (id) => Number(id) || null,
      attributeId: (attributeId) => Number(attributeId),
      orderIndex: (orderIndex) => Number(orderIndex),
      showInList: (showInList) => Boolean(showInList),
      showInForm: (showInForm) => Boolean(showInForm),
      required: (required) => Boolean(required) || null,
    }
  }

  getAttribute() {
    if (this.attributeId != null) {
      let attribute = new Attribute({id: this.attributeId})
      return attribute.fetch().then(() => attribute)
    }
    return Promise.reject()
  }

  async fetch(options = {}) {
    let defaultHeaders = await Ajax.getDefaultHeaders()
    options.headers = {...options.headers, ...defaultHeaders}
    return super.fetch(options)
  }

  routes() {
    return {
      fetch: `${process.env.VUE_APP_HEALTHBANK_HOST}api/v0/attributeDisplaySetting/{id}`,
    }
  }
}
