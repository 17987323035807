<template>
  <div class="mcf-no-objects">
    <div class="mcf-no-objects-title">{{$t('object.display.no_objects.title.yourForms')}}</div>
    <div class="mcf-no-objects-subtitle">{{$t('object.display.no_objects.subtitle')}}</div>
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.mcf-no-objects {
  @include flexbox;
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  height: 360px;
  @include background('~@/assets/images/defaults/no-forms.png', 50% 50%);
  @include background-size(contain);
  margin: 0 0 20px;
  padding: 0 40px;
  @include mq(tablet-wide, min) {
    height: 560px;
  }

  &-title {
    font-family: $main-font;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.22;
    color: $black;
    margin: 0 0 11px;
  }

  &-subtitle {
    font-family: $main-font;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.23;
    text-align: center;
    color: $black;
  }
}
</style>
