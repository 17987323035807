<template>
  <div class="mcf-version-object" v-if="isRender">
    <div class="mcf-version-object-head">
      <div class="mcf-version-object-head-left">
        <div class="mcf-version-object-head-title">
          <div class="mcf-version-object-head-inner">
            <div class="mcf-round"
                 :class="objectCompletedStatus"></div>
            <div>{{ type.name }}</div>
          </div>
          <div class="mcf-version-object-date-status">{{ objectCompletedDate }}</div>
        </div>
      </div>
      <div class="mcf-version-object-head-right">
        <div class="mcf-version-object-status"
             :class="{'mcf-deprecated': object.deprecated}">
          <div class="mcf-round"></div>
          <div v-if="!object.deprecated">{{ $t('object.history.version_latest') }}</div>
          <div v-else>{{ $t('object.history.version_expired') }}</div>
        </div>
        <div class="mcf-version-object-owner-actions">
          <a href="javascript:;"
             @click.prevent="moveToShow"
             class="mcf-icon mcf-icon-arrow-right"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {DateFormatter} from "@/util/DateFormatter";

const SELECTORS = {
  IS_COMPLETED_OBJECT_CLASS: 'mcf-completed',
  IS_NO_COMPLETED_OBJECT_CLASS: 'mcf-no-completed'
}
//properties
const props = defineProps({
  object: Object || null
})

//state
const {t} = useI18n();
const isRender = ref(false)
const type = ref(null)
const objectCompletedStatus = computed(() => props.object.completed ? SELECTORS.IS_COMPLETED_OBJECT_CLASS : SELECTORS.IS_NO_COMPLETED_OBJECT_CLASS)
const objectCompletedDate = computed(() => props.object.completedDate ? t('object.data.completedDate', [DateFormatter.formatShowDate(new Date(props.object.completedDate))]) : "")

//emits
const emit = defineEmits(['mutation-history-object-init', 'router-object-by-id']);

//methods
async function prepareTypeForRendering() {
  await props.object.getType()
    .then((t) => type.value = t)
}

function moveToShow() {
  emit('router-object-by-id')
}

//lifecycle
onMounted(async () => {
  await prepareTypeForRendering()
  isRender.value = true
  emit('mutation-history-object-init')
})
</script>

<style scoped lang="scss">
.mcf-version-object {
  padding: 0 0 8px;

  &-head {
    position: relative;
    @include flexbox;
    @include align-items(center);
    background: #fff;
    box-shadow: 0px 2px 10px #F1F2F7, 0px 0px 4px rgba(241, 242, 247, 0.8);
    border-radius: 8px;
    padding: 12px 16px;
    font-family: $main-font;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.19;
    color: #020F17;

    &-title {
      @include flexbox;
      @include flex-direction(column);
      @include flex-grow(1);

      .mcf-round {
        height: 16px;
        width: 16px;
        min-width: 16px;
        border-radius: 50%;
        margin: 0 8px 0 0;
        background: $main-color;

        &.mcf-no-completed {
          background: $no-completed-color;
        }

        &.mcf-completed {
          background: $completed-color;
        }
      }
    }

    &-inner {
      @include flexbox;
      @include align-items(center);
      margin: 3px 0;
    }

    &-left {
      @include flexbox;
      @include align-items(flex-start);
      @include flex-direction(column);
      @include flex-grow(1);
      padding: 0 5px 0 0;
    }

    &-right {
      @include flexbox;
      @include align-items(center);
    }
  }

  &-owner-actions {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-end);
    @include flex-grow(1);

    .mcf-icon {
      display: block;
      font-size: 14px;
      color: #B5BFC5;
      margin: 0 6px;
      text-decoration: none;
      @include transition($main-transition);
      @include mq(tablet-wide, min) {
        font-size: 15px;
        &:hover {
          color: $main-color;
        }
      }
    }
  }

  &-date-status {
    font-family: $main-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.21;
    color: rgba(2, 15, 23, .34);
  }

  &-status {
    @include flexbox;
    @include align-items(center);
    @include flex-shrink(0);
    padding: 4px 5px;
    background: rgba(230, 233, 241, 0.58);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.25;
    color: $completed-color;
    margin: 0 8px 0 3px;

    .mcf-round {
      height: 10px;
      width: 10px;
      min-width: 10px;
      margin: 0 4px 0 0;
      background: $completed-color;
      border-radius: 50%;
    }

    &.mcf-deprecated {
      color: #C2C8CC;

      .mcf-round {
        background: #C2C8CC;
      }
    }
  }
}
</style>
