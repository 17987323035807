<template>
  <not-found-page v-if="isPageNotFound"/>
  <router-view v-else/>
</template>

<script setup>
import {computed} from "vue";
import {useAppStore} from "@/stores/useAppStore";
import NotFoundPage from "@/views/error/NotFoundPage.vue";

//state
const store = useAppStore()
const isPageNotFound = computed(() => store.isPageNotFound);
</script>

<style scoped lang="scss">
.mcf-error-page {
  padding: 60px 0 160px;
  width: 100%;
  @include mq(tablet-wide, max) {
    padding: 35px 0;
  }
}

.mcf-error-page-404 {
  position: relative;
  @include flexbox;
  @include align-items(center);
  min-height: 388px;

  .mcf-error-pos {
    position: relative;
    @include flex-grow(1);
    padding: 0 20px 0 0;
    z-index: 1;
  }

  .mcf-error-img {
    width: 46%;

    img {
      height: auto;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }

  @include mq(tablet-wide, max) {
    @include flex-direction(column);
    .mcf-error-pos {
      padding: 0 0 50px;
    }
    .mcf-error-img {
      width: 100%;
      text-align: center;

      img {
        max-width: 270px;
      }
    }
  }
  @include mq(tablet, max) {
    .mcf-error-img {
      img {
        max-width: 200px;
      }
    }
  }
}

.mcf-error-page-title {
  position: relative;
  font-family: $additional-font-2;
  font-weight: 700;
  font-size: 60px;
  line-height: 1.2;
  color: $black;
  padding: 0 0 17px;
  margin: 0 0 20px;

  &:before {
    @include pseudo();
    bottom: 0;
    left: 0;
    height: 1px;
    width: 80px;
    background: rgba($brand-color, 0.3);
  }

  @include mq(desktop, max) {
    font-size: 50px;
  }
  @include mq(tablet-wide, max) {
    font-size: 40px;
  }
}

.mcf-error-page-info {
  font-family: $additional-font-2;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;
  width: 50%;
  @include mq(tablet-wide, max) {
    width: 100%;
  }
}
</style>
