<template>
  <div>
    <spinner-element :loading="isLoading"/>

    <div v-if="isNameExist">
      <div v-if="name.toLowerCase().endsWith('.pdf')">
        <div v-if="!isEmbedUrl"
             class="mcf-button-wrapper mcf-button-end">
          <a href="#"
             class="mcf-button-fill"
             @click.prevent="downloadFile()">{{ $t('general.download') }}
            <i class="mcf-icon mcf-icon-PDF"></i>
          </a>
        </div>

        <pdf-preview
          :pdf-url="pdfUrl"
          v-on:is-rendered-object="hideSpinner"></pdf-preview>
      </div>
      <div v-else>
        <a href="#"
           class="mcf-value"
           v-text="name"
           :class="{ 'mcf-with-name': name }"
           @click.prevent="downloadFile()"></a>
        <div v-if="isImage">
          <img :src="imgUrl"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SpinnerElement from "@/components/SpinnerElement.vue";
import {useSpinner} from "@/composables/useSpinner";
import {computed, onMounted, ref, toRef} from "vue";
import {useRoute} from "vue-router";
import {uploadedFileApi} from "@/api/uploadedFiles";
import AttributeType from "@/components/patientData/AttributeTypes";
import PdfPreview from "@/components/pdf/PdfPreview.vue";

// Props
const props = defineProps({
  renderData: Array,
  objectId: Number,
  displaySetting: null,
});

//state
const renderData = toRef(props, 'renderData');
const route = useRoute();
const name = ref(null)
const imgUrl = ref(null)
const pdfUrl = computed(() => {
  const uploadedProperty = uploadedFileProperty()
  if (uploadedProperty) {
    return `${process.env.VUE_APP_HEALTHBANK_HOST}api/file/${uploadedProperty.id}`
  }
  return null
})
const isEmbedUrl = computed(() => route.query?.displayType === 'embed');
const isImage = computed(() => {
  const lowerName = name.value.toLowerCase()
  const extensions = ['.jpg', '.jpeg', '.png', '.gif']

  for (const extension of extensions) {
    if (lowerName.endsWith(extension)) {
      return true
    }
  }
  return false
});
const isNameExist = computed(() => name.value && name.value !== '-')
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()

//methods
async function fetchImage() {
  if (!isImage.value) {
    return
  }
  const uploadedProperty = uploadedFileProperty()
  if (uploadedProperty != null && uploadedProperty.id != null) {
    imgUrl.value = await uploadedFileApi.fetchFileInBase64(uploadedProperty.id, name.value)
  }
}

function uploadedFileProperty() {
  let uploadedProperties = renderData.value.filter(it => it.attribute.get('attributeType') === AttributeType.UPLOADED)
  if (uploadedProperties.length) {
    return uploadedProperties[0].properties[0]
  }
}

async function downloadFile() {
  let uploadedProperty = uploadedFileProperty()
  if (uploadedProperty != null && uploadedProperty.id != null) {
    await uploadedFileApi.downloadFile(uploadedProperty.id, name.value)
  }
}

async function fetchPdfName() {
  let uploadedProperty = uploadedFileProperty()
  if (uploadedProperty != null && uploadedProperty.id != null) {
    let resp = await uploadedFileApi.getFileInfo(uploadedProperty.id)
    let fileInfo = resp.data
    if (fileInfo.name != null) {
      name.value = fileInfo.name
    }
  }
  if (uploadedProperty.value === null) {
    name.value = '-'
  }
}

//lifecycle
onMounted(async () => {
  showSpinner()
  await fetchPdfName()
  await fetchImage()
  if (!isNameExist.value) {
    hideSpinner()
  }
})
</script>

<style scoped lang="scss">

</style>
