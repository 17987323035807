<template>
  <div class="mcf-objects-wrapper"
       :class="{'mcf-loading': !Boolean(objects)}">
    <div class="mcf-objects-body">
      <spinner-element
        :loading="!!isLoading"
        :size="'35px'"
        :position="'absolute'"/>
      <div v-if="Boolean(objects) && objects.length">
        <div v-for="(object, i) in objects.models" :key="i">
          <panel-object-renderer
            v-if="Boolean(object)"
            :render-mode="renderMode"
            :object="object"
            :updateObjectsListEventBus="updateObjectsListEventBus"
            :with-additional-object-info="withAdditionalObjectInfo"
            @panel-object-header-init="checkPanelRender"
          />
        </div>
        <base-pagination
          v-if="!isNaN(pageCount)"
          :current-page="page"
          :size="size"
          :page-count="pageCount"
          @load-next-page="pageChangeHandle('next')"
          @load-previous-page="pageChangeHandle('previous')"
          @load-by-page="pageChangeHandle"
        />
      </div>
      <div v-else-if="Boolean(objects) && objects.length === 0">
        <panel-no-object-renderer/>
      </div>

    </div>
  </div>
</template>

<script setup>
import SpinnerElement from "@/components/SpinnerElement.vue";
import PanelObjectRenderer from "@/components/patientData/objects/PanelObjectRenderer.vue";
import PanelNoObjectRenderer from "@/components/patientData/objects/PanelNoObjectRenderer.vue";
import {useSpinner} from "@/composables/useSpinner";
import ObjectList from "@/models/patientData/ObjectList";
import RenderMode from "@/components/patientData/RenderMode";
import {onMounted, ref} from "vue";
import BasePagination from "@/views/pagination/BasePagination.vue";

// Props
const props = defineProps({
  objects: ObjectList || null,
  page: {
    type: Number,
    required: false,
    default: 0
  },
  size: {
    type: Number,
    required: false,
    default: 10
  },
  pageCount: {
    type: Number,
    required: false,
    default: 0
  },
  updateObjectsListEventBus: {
    required: false,
  },
  withAdditionalObjectInfo: {
    type: Boolean,
    required: false,
    default: true
  }
});

//state
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const renderMode = RenderMode.LIST_ITEM
const currentPage = ref(props.page)
const panelRenderCount = ref(0)

//emits
const emit = defineEmits(['load-by-page'])

//methods
function checkPanelRender() {
  panelRenderCount.value++
  if (panelRenderCount.value === props.objects.length) {
    hideSpinner()
    panelRenderCount.value = 0
  }
}

function pageChangeHandle(value) {
  showSpinner()

  switch (value) {
    case 'next':
      currentPage.value += 1
      break
    case 'previous':
      currentPage.value -= 1
      break
    default:
      currentPage.value = value
      break
  }
  emit('load-by-page', currentPage.value)
}

//lifecycle
onMounted(() => {
  if (props.updateObjectsListEventBus) {
    props.updateObjectsListEventBus.on('update-objects-list', () => {
      showSpinner()
    })
  }
  showSpinner()
})
</script>

<style scoped lang="scss">
.mcf-objects-body {
  position: relative;
  min-height: 160px;
}
</style>
