<template>
  <div class="mcf-content-text">
    <div v-html="userAgreement"></div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {ContentDataApi} from "@/api/contentData";

//state
const userAgreement = ref("")
userAgreement.value = await ContentDataApi.getInstance().fetchUserAgreement();

//lifecycle
onMounted(() => {
  window.scrollTo(0, 0)
})
</script>
