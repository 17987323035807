<template>
  <div v-if="isRender">
    <properties-create-renderer
      :renderData="renderData"
      class="mcf-info-content"/>
  </div>
</template>

<script setup>
import Type from "@/models/patientData/Type";
import {onMounted, ref} from "vue";
import Property from "@/models/patientData/Property";
import ObjectData from "@/components/patientData/ObjectData";
import PropertiesCreateRenderer from "@/components/patientData/properties/PropertiesCreateRenderer.vue";

//properties
const props = defineProps({
  type: Type || null
})

//state
const renderData = ref([])
const isRender = ref(false)

//methods
async function prepareAttributesForRendering() {
  const attrs = await props.type.getAttributes()
  await attrs.sortByOrderIndex()

  attrs.each((attr, index) => {
    let property = new Property()
    if (attr.list) {
      property.index = 0
    }
    renderData.value[index] = new ObjectData(attr, [property], null, false, false)
  })
}

//lifecycle
onMounted(async () => {
  await prepareAttributesForRendering()
  isRender.value = true
})
</script>
