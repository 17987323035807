<template>
  <div class="mcf-container">
    <object-create-data
      v-if="isRender"
      :type="type"
      class="mcf-content-wrapper"/>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import Type from "@/models/patientData/Type";
import ObjectCreateData from "@/components/patientData/objects/ObjectCreateData.vue";

//state
const route = useRoute()
const type = ref(null)
const isRender = ref(false)

//lifecycle
onMounted(async () => {
  if (route.params != null && route.params.typeId != null) {
    type.value = new Type({id: route.params.typeId})
    await type.value.fetch()
    isRender.value = true
  } else {
    console.log('move to 404 !')
  }
})
</script>
