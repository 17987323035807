<template>
  <div class="mcf-container">
    <div class="mcf-show-my-object-page">
      <h1 class="mcf-objects-title">{{ $t('showMyObjectsPage.title') }}</h1>
      <panel-object-list
        :objects="objects"
        :page="page"
        :size="size"
        :pageCount="pageCount"
        :updateObjectsListEventBus="updateObjectsListEventBus"
        @load-by-page="pageChangeHandle"
      />
    </div>
  </div>
</template>

<script setup>
import mitt from 'mitt'
import PanelObjectList from "@/views/object/PanelObjectList.vue";
import {onMounted, ref, unref} from "vue";
import {ObjectsApi} from "@/api/objects";

//state
const page = ref(0)
const size = ref(10)
const pageCount = ref(0)
const objects = ref(null)
const updateObjectsListEventBus = mitt()

//methods
function pageChangeHandle(pg) {
  page.value = pg
  updateObjects()
}

function calculatePagesCount(objectCount) {
  return Math.ceil(objectCount / size.value)
}

async function updateObjects() {
  objects.value = null
  objects.value = await ObjectsApi.getInstance().getUserObjects(unref(page), unref(size))
}

//lifecycle
onMounted(async () => {
  pageCount.value = calculatePagesCount(await ObjectsApi.getInstance().countUserObjects())
  objects.value = await ObjectsApi.getInstance().getUserObjects(unref(page), unref(size))

  updateObjectsListEventBus.on('update-objects-list', () => {
    updateObjects()
  })
})

</script>

<style scoped lang="scss">
.mcf-show-my-object-page {
  width: 100%;
}

</style>
