<template>
  <div v-show="item.isReadyToModify"
       class="mcf-input-wrapper mcf-checkbox-wrapper"
       :class="{'mcf-error': isFormInvalid && item.isShowError}">
    <input type="checkbox"
           v-model="form"
           :id="getCheckboxId"
           @focusin="dontShowValidationError"
           @focusout="showValidationError"
           class="mcf-input"
           @input="updateValue"/>
    <label :for="getCheckboxId"
           class="mcf-label mcf-label-checkbox">
      {{ item.attribute.name }}
      <em class="mcf-icon mcf-icon-check"></em>
    </label>
    <div v-if="v$ && item.isShowError"
         class="mcf-validation-errors-wrapper">
      <div v-for="($error, i) in v$.$errors"
           :key="i"
           class="mcf-validation-errors">
        <div class="mcf-validation-error">
          {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ObjectData from "@/components/patientData/ObjectData";
import {computed, onMounted, reactive, toRef, unref} from "vue";
import {useVuelidate} from "@vuelidate/core";
import {isTrue} from "@/components/patientData/properties/validators/CustomValidators";
import {useValidationErrors} from "@/composables/useValidationErrors";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const autoFillData = toRef(props, 'autoFillData');
const form = reactive({value: property.value?.value ?? ""});
const rules = computed(() => ({
  form: {
    value: unref(item).attribute.currentDisplaySetting.required ? {isTrue: isTrue()} : {}
  }
}));
const v$ = useVuelidate(rules, {form});
const getCheckboxId = computed(() => `${unref(item).attribute.attributeType}-${unref(item).attribute.id}`);
const isFormInvalid = computed(() => v$.value.form.$invalid);
const {getErrorMessage} = useValidationErrors()

// Methods
function updateValue() {
  form.value = !form.value
  property.value.value = form.value;
}

function showValidationError() {
  item.value.isShowError = true;
}

function dontShowValidationError() {
  item.value.isShowError = false;
}

function setFormValue() {
  item.value.validator = v$;
  item.value.isReadyToModify = true;

  if (unref(property)?.value == null) {
    if (unref(autoFillData)?.value != null) {
      property.value.value = unref(autoFillData).value
    } else {
      property.value.value = false
    }
  }
  form.value = unref(property).value
}

//lifecycle
onMounted(() => {
  setFormValue()
})
</script>
