import { ref, unref } from 'vue'

export function useSpinner () {
  const isLoading = ref(false)
  const loaderCount = ref(0)

  function showSpinner () {
    loaderCount.value++
    if (loaderCount.value > 0 && !unref(isLoading)) {
      isLoading.value = true
    }
  }

  function hideSpinner () {
    loaderCount.value--
    if (unref(loaderCount) === 0) {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    showSpinner,
    hideSpinner
  }
}
