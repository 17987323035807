import {ACCESS_COOKIE_NAME, AuthApi, REFRESH_COOKIE_NAME} from '@/api/authorization'
import Cookies from './Cookies'
import UrlManager from './urlManager'

class AuthorizationService {
  constructor () {
    this.authApi = AuthApi.getInstance()
  }

  async setAuthParamsFromUrl () {
    const urlParams = new URLSearchParams(window.location.search)
    if (!urlParams.has(ACCESS_COOKIE_NAME) && !urlParams.has(REFRESH_COOKIE_NAME)) {
      return
    }

    if (urlParams.has(ACCESS_COOKIE_NAME)) {
      Cookies.setAccessToken(urlParams.get(ACCESS_COOKIE_NAME))
      urlParams.delete(ACCESS_COOKIE_NAME)
    }
    if (urlParams.has(REFRESH_COOKIE_NAME)) {
      Cookies.setRefreshToken(urlParams.get(REFRESH_COOKIE_NAME))
      urlParams.delete(REFRESH_COOKIE_NAME)
    }

    if (urlParams.toString()) {
      window.location.search = urlParams.toString()
    } else {
      window.location.href = window.location.href.split('?')[0]
    }
  }

  async getAuthorization () {
    try {
      await this.authApi.isAuthorized()
    } catch (httpError) {
      switch (httpError.response.status) {
        case 401:
          await this.refreshToken()
          break
        default:
          await this.logout()
          break
      }
    }
  }

  async refreshToken () {
    try {
      const authInfo = await this.authApi.refreshToken()
      if (authInfo && authInfo.data) {
        Cookies.setAccessToken(authInfo.data.accessToken)
        Cookies.setRefreshToken(authInfo.data.refreshToken)
        await this.getAuthorization()
      } else {
        await this.logout()
      }
    } catch (httpError) {
      await this.logout()
    }
  }

  async logout () {
    try {
      await this.authApi.logout()
      Cookies.removeAccessToken()
      Cookies.removeRefreshToken()
    } catch (httpError) {
      console.log(httpError)
    } finally {
      this.redirectToAuthService()
    }
  }

  redirectToAuthService() {
    const queryParams = {
      serviceName: 'web-app',
      backUrl: window.location.href,
      apiKey: process.env.VUE_APP_WEB_APP_KEY
    }
    window.location = UrlManager.composeLocaleUrl(process.env.VUE_APP_AUTH_CLIENT, '', queryParams)
  }

  getUserCurrentName () {
    const accessToken = Cookies.getAccessToken()
    if (!accessToken) {
      return null
    }

    const jwtBody = this.parseJwt(accessToken)
    if (!jwtBody) {
      return null
    }
    return `${jwtBody.firstName} ${jwtBody.lastName}`
  }

  parseJwt (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
  }
}

let authService = null

export const AuthService = {
  getInstance () {
    if (authService == null) {
      authService = new AuthorizationService()
    }
    return authService
  }
}
