<template>
  <div class="mcf-container">
    <!--finalize object modal-->
    <modal-direction
      v-if="modalOpen"
      :is-open="modalOpen"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon">
          <em class="mcf-icon mcf-icon-check2" style="font-size: 17px"></em>
        </div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{ $t("object.display.completed.success.title") }}</h1>
        <h1 class="mcf-modal-subtitle">{{ $t("object.display.completed.success") }}</h1>
      </template>
      <template v-slot:buttons>
        <a href="#"
           class="mcf-button-transparent"
           @click.prevent="hideModal">{{ $t("general.close") }}</a>
      </template>
    </modal-direction>
    <router-link
      v-if="!isEmbedUrl"
      :to="localizeRouteLink('/show-my-objects')"
      class="mcf-breadcrumbs">
      <em class="mcf-icon mcf-icon-arrow-right"></em>{{ $t('navigation.back_to_forms') }}
    </router-link>
    <div
      v-if="isRender"
      class="mcf-content-wrapper"
      ref="contentWrapperRef">
      <spinner-element
        :loading="isLoading"
        :showTextOnLongLoading="true"/>
      <div v-if="object != null && !object.completed && notCompletedObjectNotificationHeader"
           class="mcf-notification mcf-notification-top"
           ref="notificationShow"
           v-show="!isLoading">
        <i class="mcf-icon mcf-icon-exclamation-mark"></i>
        {{ notCompletedObjectNotificationHeader }}
        <div class="mcf-button-wrapper mcf-button-right">
          <input @click="finalizeObject"
                 type="button"
                 class="mcf-button-fill mcf-button-sm"
                 :value="completeBtnLabel">
        </div>
      </div>
      <div v-if="objectDisplaySettings.length > 1 && !isLoading"
           class="mcf-display-mode-wrapper">
        <a href="#"
           v-for="(item, i) in objectDisplaySettings"
           :key="i"
           @click.prevent="onDisplaySettingClick(item)"
           :class="[item.id === activeDisplaySetting.id? 'mcf-active' : '', ' mcf-display-mode-item']">
          {{ item.name ? item.name : $t('object.display.' + item.displayMethod.toLowerCase()) }}
        </a>
      </div>

      <object-renderer
        v-if="Boolean(object)"
        :object="object"
        :render-mode="renderMode"
        :display-setting="activeDisplaySetting"
        v-on:is-rendered-object="onReadyToRender"/>

      <div v-show="!isLoading && object != null && !object.completed">
        <div class="mcf-info-box-fixed"
             ref="fixedBlockRef">
          <div v-if="notCompletedObjectNotificationFooter"
               class="mcf-notification">{{ notCompletedObjectNotificationFooter }}
          </div>
          <div class="mcf-button-wrapper mcf-button-center">
            <input
              @click="moveToEdit"
              type="button"
              class="mcf-button-transparent"
              :value="$t('general.edit')"/>
            <input
              @click="finalizeObject"
              type="button"
              class="mcf-button-fill"
              :value="completeBtnLabel">
          </div>
        </div>
      </div>

      <div v-show="!isLoading"
           class="mcf-bottom-wrapper"
           ref="bottomWrapRef">
        <div class="mcf-notification-wrapper"
             ref="bottomBlockRef">
          <div class="mcf-notification"
               v-if="object != null && !object.completed && notCompletedObjectNotificationFooter">
            {{ notCompletedObjectNotificationFooter }}
          </div>
          <div class="mcf-submit-date">{{ $t("object.display.submitDate") }}:<span>{{ signedDate }}</span></div>
        </div>

        <div v-if="object != null && !object.completed"
             class="mcf-button-wrapper mcf-button-center">
          <input
            @click="moveToEdit"
            type="button"
            class="mcf-button-transparent"
            :value="$t('general.edit')"/>
          <input
            @click="finalizeObject"
            type="button"
            class="mcf-button-fill"
            :value="completeBtnLabel">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Obj} from "@/models/patientData/Object";
import ModalDirection from "@/components/ModalDirection.vue";
import SpinnerElement from "@/components/SpinnerElement.vue";
import ObjectRenderer from "@/components/patientData/objects/ObjectRenderer.vue";
import {useSpinner} from "@/composables/useSpinner";
import RenderMode from "@/components/patientData/RenderMode";
import {computed, onMounted, onUnmounted, ref, unref, useTemplateRef} from "vue";
import {DateFormatter} from "@/util/DateFormatter";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {ObjectsApi} from "@/api/objects";
import {useAppStore} from "@/stores/useAppStore";
import UrlManager from "@/util/urlManager";

const SELECTORS = {
  ACTIVE_CLASS: 'mcf-active',
  NO_ACTIVE_CLASS: 'mcf-no-active'
}

//state
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const store = useAppStore()
const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const bottomBlockRef = useTemplateRef('bottomBlockRef')
const contentWrapperRef = useTemplateRef('contentWrapperRef')
const bottomWrapRef = useTemplateRef('bottomWrapRef')
const fixedBlockRef = useTemplateRef('fixedBlockRef')
const object = ref(null)
const objectDisplaySettings = ref([])
const activeDisplaySetting = ref(null)
const modalOpen = ref(false)
const readyToRender = ref(false)
const isRender = ref(false)
const signedDate = computed(() => {
  if (object.value != null && object.value.completedDate) {
    return DateFormatter.formatShowDateTime(new Date(object.value.completedDate))
  } else {
    return DateFormatter.formatShowDate(new Date(Date.now()))
  }
})
const renderMode = computed(() => {
  if (unref(activeDisplaySetting)?.displayMethod === "PDF") {
    return RenderMode.SHOW_PDF
  } else if (unref(activeDisplaySetting)?.displayMethod === "UPLOADED_PDF") {
    return RenderMode.SHOW_UPLOADED_PDF
  } else {
    return RenderMode.SHOW_ITEM
  }
})
const isEmbedUrl = computed(() => route.query?.displayType === 'embed')
const notCompletedObjectNotificationHeader = computed(() => {
  if (unref(activeDisplaySetting)?.notificationHeader) {
    if (!unref(activeDisplaySetting).notificationHeader.trim().length) {
      return "";
    }
    return unref(activeDisplaySetting).notificationHeader
  }
  return t("object.display.show.notification")
})
const notCompletedObjectNotificationFooter = computed(() => {
  if (unref(activeDisplaySetting)?.notificationFooter) {
    if (!unref(activeDisplaySetting).notificationFooter.trim().length) {
      return "";
    }
    return unref(activeDisplaySetting).notificationFooter
  }
  return t("object.display.submitDate.notification")
})
const completeBtnLabel = computed(() => {
  if (unref(activeDisplaySetting)?.completeButtonLabel) {
    return unref(activeDisplaySetting).completeButtonLabel;
  }
  return t('object.form.finalize')
})

//methods
function localizeRouteLink(uri) {
  return UrlManager.composeLocaleUrl('', uri)
}

function hideModal() {
  modalOpen.value = false
}

function handleScroll() {
  if (isLoading.value) {
    return
  }
  if (!object.value.completed) {
    recalculateFixedBlock()
  }
}

function recalculateFixedBlock() {
  let windowHeight = window.innerHeight,
    scrollTop = window.pageYOffset || document.body.scrollTop,
    bottomBlockOffsetTop = bottomBlockRef.value.offsetTop,
    contentWrapper = contentWrapperRef.value,
    contentWrapperStyle = getComputedStyle(contentWrapper),
    contentWrapperMarginTop = parseInt(contentWrapperStyle.marginTop),
    headerHeight = 80

  if ((scrollTop + windowHeight) >= (bottomBlockOffsetTop + headerHeight + contentWrapperMarginTop)) {
    !fixedBlockRef.value.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && fixedBlockRef.value.classList.add(SELECTORS.NO_ACTIVE_CLASS)
    bottomWrapRef.value.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && bottomWrapRef.value.classList.remove(SELECTORS.NO_ACTIVE_CLASS)
  } else {
    fixedBlockRef.value.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && fixedBlockRef.value.classList.remove(SELECTORS.NO_ACTIVE_CLASS)
    !bottomWrapRef.value.classList.contains(SELECTORS.NO_ACTIVE_CLASS) && bottomWrapRef.value.classList.add(SELECTORS.NO_ACTIVE_CLASS)
  }
}

function onReadyToRender() {
  readyToRender.value = true
  hideSpinner()
  recalculateFixedBlock()
}

function moveToEdit() {
  router.push({path: objectEditLink()})
}

function objectEditLink() {
  if (isEmbedUrl.value) {
    return '/object/edit/' + object.value.id + '?displayType=embed'
  } else {
    return '/object/edit/' + object.value.id
  }
}

function finalizeObject() {
  ObjectsApi.getInstance()
    .completeObject(object.value.id)
    .then((response) => {
      if (response.data != null) {
        object.value.completed = response.data.completed;
        modalOpen.value = true
      }
    })
    .catch((e) => console.log(e))
}

function onDisplaySettingClick(setting) {
  activeDisplaySetting.value = setting
}

//lifecycle
onMounted(async () => {
  window.addEventListener('scroll', handleScroll)
  showSpinner()

  if (route.params != null && route.params.id != null) {
    store.setPageNotFound(false)
    let obj = new Obj({id: route.params.id})

    await obj.fetch()
      .catch((data) => {
        switch (data.error.response.status) {
          case 404:
            store.setPageNotFound(true)
            return
        }
      })
    if (obj.isNested) {
      store.setPageNotFound(true)
    }

    let allTypeDisplaySettings = await obj.getDisplaySettings();
    objectDisplaySettings.value = allTypeDisplaySettings.filter(it => it.displayPage === "SHOW");
    if (objectDisplaySettings.value.length > 0) {
      activeDisplaySetting.value = objectDisplaySettings.value[0];

    }
    object.value = obj
    isRender.value = true
  } else {
    store.setPageNotFound(true)
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped lang="scss">
.mcf-display-mode-wrapper {
  @include flexbox;
  border-radius: 100px;
  background: transparent;
  border: 1px solid $main-color;
  margin: 10px 0;
  overflow: hidden;
}

.mcf-display-mode-item {
  position: relative;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  flex: 1 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.05;
  text-align: center;
  color: $main-color;
  min-height: 32px;
  padding: 10px 8px;
  @include transition($main-transition);

  &:not(:last-child):before {
    @include pseudo();
    top: 2px;
    bottom: 2px;
    right: 0;
    width: 1px;
    background: rgba(135, 168, 212, 0.5);
    opacity: 0.75;
  }

  &.mcf-active {
    color: #ffffff;
    background: $main-color;
  }

  @include mq(tablet-wide, min) {
    font-size: 14px;
    min-height: 42px;
    &:hover {
      color: #ffffff;
      background: $main-color;
    }
  }
}

.mcf-submit {
  &-date {
    font-family: $main-font;
    font-size: 12px;
    font-weight: 500;
    color: $text-color;
    text-align: center;
    margin: 10px 0;

    span {
      display: inline-block;
      font-weight: 600;
      padding: 0 0 0 10px;
    }
  }

  @include mq(tablet-wide, min) {
    &-date {
      font-size: 14px;
    }
  }
}

.mcf-info-box-fixed {
  opacity: 1;
  visibility: visible;
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background: #FFFFFF;
  @include box-shadow(0px -4px 17px rgba(167, 167, 167, 0.1));
  @include transition(opacity 0.1s, visibility 0.1s);
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  @include mq(tablet-wide, min) {
    width: 778px;
  }
  @include mq(desktop, min) {
    width: 1022px;
  }
  @include mq(desktop-wide, min) {
    width: 1013px;
  }

  &.mcf-no-active {
    opacity: 0;
    visibility: hidden;
  }

  .mcf-button-wrapper {
    padding: 5px 0;
  }
}

.mcf-bottom-wrapper {
  &.mcf-no-active {
    opacity: 0;
    visibility: hidden;
  }
}

.mcf-breadcrumbs {
  position: relative;
  @include flexbox;
  @include align-items(center);
  margin: $content-margin-mob;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: $main-color;
  padding: 0 0 0 37px;

  .mcf-icon {
    @include transform(rotate(180deg));
    @include transition($main-transition);
    position: absolute;
    left: 12px;
    top: 2px;
  }

  @include mq(tablet-wide, min) {
    margin: $content-margin;
    margin-bottom: 0;
    &:hover {
      .mcf-icon {
        left: 9px;
      }
    }
  }
}
</style>
