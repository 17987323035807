<template>
  <div v-if="isRender"
       class="mcf-input-wrapper mcf-datetime-wrapper">
    <label v-show="showAttributeName"
           class="mcf-label">{{ item.attribute.name }}:</label>
    <div class="mcf-input-datetime-wrapper">
      <input type="text"
             class="mcf-input"
             :placeholder="$t('field.year')"
             v-model="form.year"
             @input="updateValue"/>
      <input type="text"
             class="mcf-input"
             :placeholder="$t('field.month')"
             v-model="form.month"
             @input="updateValue"/>
      <input type="text"
             class="mcf-input"
             :placeholder="$t('field.day')"
             v-model="form.day"
             @input="updateValue"/>
      <div class="mcf-time-wrapper">
        <input type="text"
               class="mcf-input"
               :placeholder="$t('field.hours')"
               v-model="form.hours"
               @input="updateValue"/>
        <span class="mcf-separator">:</span>
        <input type="text"
               class="mcf-input"
               :placeholder="$t('field.minutes')"
               v-model="form.minutes"
               @input="updateValue"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref, toRef, unref} from "vue";
import ObjectData from "@/components/patientData/ObjectData";
import {DateFormatter} from "@/util/DateFormatter";

//properties
const props = defineProps({
  item: ObjectData,
  property: Object,
})

//state
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const isRender = ref(false)
const form = reactive({
  year: 0, // todo: discuss it
  month: 0,
  day: 0,
  hours: 0,
  minutes: 0
})
const showAttributeName = computed(() => !unref(item).attribute.list)

//methods
const setForm = (value) => {
  return {
    year: DateFormatter.getFormatYear(value),
    month: DateFormatter.getFormatMonth(value),
    day: DateFormatter.getFormatDay(value),
    hours: DateFormatter.getFormatHours(value),
    minutes: DateFormatter.getFormatMinutes(value)
  }
}

function prepareItemAndProperty() {
  isRender.value = true
  item.value.isReadyToModify = true

  if (unref(property)?.value != null) {
    Object.assign(form, setForm(new Date()));
  }
}

function updateValue() {
  let updatedDate = new Date(form.year, form.month, form.day, form.hours, form.minutes)
  property.value.value = updatedDate.getTime()
}

//lifecycle
onMounted(() => {
  prepareItemAndProperty()
})
</script>
