<template>
  <div v-show="item.isReadyToModify"
       class="mcf-input-wrapper">
    <div class="mcf-label">{{ item.attribute.name }}</div>
    <div class="mcf-radio-wrapper"
         :class="{'mcf-error': v$.form.value.$invalid && item.isShowError}">
      <input type="radio"
             :id="getCheckboxId + '-true'"
             :name="getCheckboxId"
             :value="true" v-model="form.value"
             @focusin="dontShowValidationError"
             @focusout="showValidationError"
             @input="updateRequiredValueTrue">
      <label :for="getCheckboxId + '-true'"
             class="mcf-label">{{ $t('general.yes') }}</label>
    </div>
    <div class="mcf-radio-wrapper"
         :class="{'mcf-error': v$.form.value.$invalid && item.isShowError}">
      <input type="radio"
             :id="getCheckboxId + '-false'"
             :name="getCheckboxId"
             :value="false" v-model="form.value"
             @focusin="dontShowValidationError"
             @focusout="showValidationError"
             @input="updateRequiredValueFalse">
      <label :for="getCheckboxId + '-false'"
             class="mcf-label">{{ $t('general.no') }}</label>
    </div>
    <div v-if="v$ && item.isShowError"
         class="mcf-validation-errors-wrapper">
      <div v-for="($error, i) in v$.$errors"
           :key="i"
           class="mcf-validation-errors">
        <div class="mcf-validation-error">
          {{ getErrorMessage($error.$validator, item.attribute.name, $error.$property, v$.form[$error.$property]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, toRef, unref} from "vue";
import {isNotNullBoolean} from "@/components/patientData/properties/validators/CustomValidators";
import {useVuelidate} from "@vuelidate/core";
import ObjectData from "@/components/patientData/ObjectData";
import {useValidationErrors} from "@/composables/useValidationErrors";

// Props
const props = defineProps({
  item: ObjectData,
  property: Object,
  autoFillData: Object,
});

//state
const {getErrorMessage} = useValidationErrors()
const item = toRef(props, 'item');
const property = toRef(props, 'property');
const autoFillData = toRef(props, 'autoFillData');
const form = reactive({value: unref(property)?.value ?? ""});
const rules = computed(() => ({
  form: {
    value: {isNotNullBoolean: isNotNullBoolean()}
  }
}))
const v$ = useVuelidate(rules, {form});
const getCheckboxId = computed(() => `${unref(item).attribute.attributeType}-${unref(item).attribute.id}`);

// Methods
function setFormValue() {
  item.value.validator = v$
  item.value.isReadyToModify = true

  if (unref(property)?.value == null) {
    if (unref(autoFillData)?.value != null) {
      property.value.value = unref(autoFillData).value
    } else if (unref(item).attribute.currentDisplaySetting.required === false) {
      property.value.value = false
    }
  }
  form.value = unref(property).value
}

const updateRequiredValueTrue = () => {
  form.value = true;
  property.value.value = true;
};

const updateRequiredValueFalse = () => {
  form.value = false;
  property.value.value = false;
};

const showValidationError = () => {
  item.value.isShowError = true;
};

const dontShowValidationError = () => {
  item.value.isShowError = false;
};

//lifecycle
onMounted(() => {
  setFormValue()
})
</script>
