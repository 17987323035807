<template>
  <div class="mcf-container">
    <div v-if="Boolean(objects)"
         class="mcf-content-wrapper">
      <spinner-element :loading="!!isLoading"/>
      <div v-for="(object, i) in objects.models"
           v-show="!isLoading"
           :key="i">
        <object-renderer
          v-if="Boolean(object)"
          :object="object"
          :render-mode="renderMode"
          :updateObjectsListEventBus="updateObjectsListEventBus"
          v-on:is-rendered-object="hideSpinner"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import SpinnerElement from "@/components/SpinnerElement.vue";
import ObjectRenderer from "@/components/patientData/objects/ObjectRenderer.vue";
import ObjectList from "@/models/patientData/ObjectList";
import RenderMode from "@/components/patientData/RenderMode";
import {onMounted} from "vue";
import {useSpinner} from "@/composables/useSpinner";

//properties
const props = defineProps({
  updateObjectsListEventBus: null,
  objects: ObjectList || null,
})

//state
const {
  isLoading,
  hideSpinner
} = useSpinner()
const renderMode = RenderMode.LIST_ITEM

//lifecycle
onMounted(() => {
  props.objects.models.forEach(() => {
    hideSpinner()
  })
})
</script>
