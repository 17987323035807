<template>
  <div ref="rootEl">
    <modal-direction
      v-if="modalOpen"
      :is-open="modalOpen"
      @modal-hide="hideModal"
    >
      <template v-slot:header>
        <div class="mcf-modal-header-icon">
          <em class="mcf-icon mcf-icon-check2" style="font-size: 17px"></em>
        </div>
      </template>
      <template v-slot:body>
        <h1 class="mcf-modal-title">{{ $t("object.display.completed.success.title") }}</h1>
        <h1 class="mcf-modal-subtitle">{{ $t("object.display.completed.success") }}</h1>
      </template>
      <template v-slot:buttons>
        <a href="#"
           class="mcf-button-transparent"
           @click.prevent="hideModal">{{ $t("general.close") }}</a>
      </template>
    </modal-direction>
    <form @submit.prevent="onSaveAndPreview"
          enctype="multipart/form-data">
      <spinner-element :loading="isLoading"/>
      <div v-show="!isLoading"
           v-for="(item, i) in renderData"
           :key="i">
        <div v-show="isShowItem(item)">
          <div v-if="allowShowAsList(item)"
               class="mcf-list-wrap">
            <div class="mcf-label ">{{ item.attribute.name }}</div>
            <vue-draggable-next :list="item.properties">
              <transition-group>
                <div class="mcf-flex-row-with-btn mcf-flex-align-start"
                     v-for="(property, index) in item.properties"
                     :key="property.index">
                  <component
                    :is="computePropertyTag(item)"
                    :item="item"
                    :objectId="objectId"
                    :property="property"
                    :autoFillData="getAutoFillData(item, property)"/>
                  <template v-if="errors.length > 0">
                    <div v-for="(error, i) in errors"
                         :key="i"
                         class="error">
                      <span>{{ error }}</span>
                    </div>
                  </template>
                  <div class="mcf-list-actions">
                    <a href="javascript:;"
                       class="mcf-icon mcf-icon-trash"
                       @click.prevent="removeProperty(item, index)"></a>
                  </div>
                </div>
              </transition-group>
            </vue-draggable-next>
            <input class="mcf-button-transparent mcf-button-sm"
                   @click.prevent="addProperty(item)"
                   type="button"
                   :value="$t('attribute.list.add.label')"/>
          </div>
          <div v-else>
            <component
              :is="computePropertyTag(item)"
              :item="item"
              :objectId="objectId"
              :property="item.properties[0]"
              :autoFillData="getAutoFillData(item, item.properties[0])"/>
            <template v-if="errors.length > 0">
              <div v-for="(error, i) in errors"
                   :key="i"
                   class="error">
                <span>{{ error }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="!isNested"
           class="mcf-button-wrapper mcf-button-center"
           v-show="!isLoading">
        <input type="submit"
               class="mcf-button-transparent"
               :value="$t('object.action.save_and_preview.button')">
        <div class="mcf-button-with-tooltip">
          <input type="button"
                 class="mcf-button-fill"
                 @click.stop="onSaveAndComplete"
                 :value="$t('object.action.save_and_send.button')">
          <div class="mcf-field-alert">
            <i class="mcf-icon mcf-icon-exclamation-mark"></i>
            <span>{{ $t('object.save_and_save.button.notification') }}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import SpinnerElement from "@/components/SpinnerElement.vue";
import {useSpinner} from "@/composables/useSpinner";
import {computed, nextTick, ref, toRef} from "vue";
import {useRoute, useRouter} from "vue-router";
import AttributeType from "@/components/patientData/AttributeTypes";
import {propertiesApi} from "@/api/properties";
import Property from "@/models/patientData/Property";
import {ObjectsApi} from "@/api/objects";
import ModalDirection from "@/components/ModalDirection.vue";
import EnumModify from "@/components/patientData/properties/modification/EnumModify.vue";
import TextModify from "@/components/patientData/properties/modification/TextModify.vue";
import LongTextModify from "@/components/patientData/properties/modification/LongTextModify.vue";
import BooleanModify from "@/components/patientData/properties/modification/BooleanModify.vue";
import NotNullBooleanModify from "@/components/patientData/properties/modification/NotNullBooleanModify.vue";
import LinkModify from "@/components/patientData/properties/modification/LinkModify.vue";
import NumericModify from "@/components/patientData/properties/modification/NumericModify.vue";
import DateModify from "@/components/patientData/properties/modification/DateModify.vue";
import DateTimeModify from "@/components/patientData/properties/modification/DateTimeModify.vue";
import ImmutableTextModify from "@/components/patientData/properties/modification/ImmutableTextModify.vue";
import UploadedModify from "@/components/patientData/properties/modification/UploadedModify.vue";
import NestedObjectModify from "@/components/patientData/properties/modification/NestedObjectModify.vue";
import {VueDraggableNext} from "vue-draggable-next";

// Props
const props = defineProps({
  renderData: Array,
  objectId: Number,
  isNested: null
});

//state
const rootEl = ref(null)
const renderData = toRef(props, 'renderData');
const objectId = toRef(props, 'objectId');
const router = useRouter()
const route = useRoute()
const modalOpen = ref(false)
const errors = ref([])
const isEmbedUrl = computed(() => route.query?.displayType === 'embed');
const componentsMap = {
  'enum': EnumModify,
  'text': TextModify,
  'long-text': LongTextModify,
  'boolean': BooleanModify,
  'not-null-boolean': NotNullBooleanModify,
  'link': LinkModify,
  'numeric': NumericModify,
  'date': DateModify,
  'date-time': DateTimeModify,
  'immutable-text': ImmutableTextModify,
  'uploaded': UploadedModify,
  'nested-object': NestedObjectModify,
}
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()

//methods
function allowShowAsList(item) {
  let attributeType = item.attribute.attributeType
  return item.attribute.list
    && attributeType !== AttributeType.BOOLEAN
    && attributeType !== AttributeType.NOT_NULL_BOOLEAN
    && attributeType !== AttributeType.IMMUTABLE_TEXT
    && attributeType !== AttributeType.UPLOADED
    && attributeType !== AttributeType.NESTED_OBJECT
}

function computePropertyTag(item) {
  return componentsMap[AttributeType.resolveTypePrefix(item.attribute.attributeType)]
}

function processNestedObjectData(objectData, data) {
  let validatedNestedData = validateRenderData(objectData.nestedObjectData)
  let isSubmit = validatedNestedData['isSubmit']
  if (objectData.attribute.list) {
    if (!data[String(objectData.attribute.id)]) {
      data[String(objectData.attribute.id)] = []
    }
    data[String(objectData.attribute.id)].push(validatedNestedData['data'])
  } else {
    data[String(objectData.attribute.id)] = validatedNestedData['data']
  }
  return {data, isSubmit};
}

function validateRenderData(renderData) {
  let data = {}
  let isSubmit = true
  showSpinner()

  renderData.forEach((it) => {
    if (!it.attribute.currentDisplaySetting.showInForm) {
      return
    }
    if (!it.isReadyToModify) {
      isSubmit = false
    }

    if (it.validator != null && it.validator.$invalid) {
      it.validator.$touch()
      it.isShowError = true
      isSubmit = false
    }

    if (it.attribute.attributeType === AttributeType.NESTED_OBJECT && it.nestedObjectData?.length > 0) {
      let processedNestedObjectData = processNestedObjectData(it, data);
      data = processedNestedObjectData['data']
      isSubmit = processedNestedObjectData['isSubmit']
    } else {
      data[String(it.attribute.id)] = getAttributeValue(it)
    }
  })
  return {data, isSubmit};
}

async function onSaveAndPreview() {
  const isSuccess = await saveObject();

  if (isSuccess) {
    await router.push({path: objectShowLink()})
  }
}

async function onSaveAndComplete() {
  const isSuccess = await saveObject();
  if (isSuccess) {
    await completeObject()
  }
}

async function saveObject() {
  let {data, isSubmit} = validateRenderData(renderData.value);
  let isSuccess = false

  if (isSubmit) {
    await propertiesApi.getInstance()
      .batchPropertiesSave(objectId.value, data)
      .then(response => {
        let props = response.data.map((prop) => new Property(prop))
        renderData.value.forEach((item) => {
          item.properties = props.filter(prop => prop.attributeId === item.attributeId)
        })
        isSuccess = true
      })
      .catch(data => {
        hideSpinner()
        switch (data.response.status) {
          case 400:
            data.response.data.errors.forEach(error => {
              if (error.reason === 'required') {
                renderData.value.forEach((renderData) => {
                  if (renderData.attribute.id === error.location) {
                    renderData.validator.$touch()
                    renderData.isShowError = true
                  }
                })
              }
            })
        }
      })
  } else {
    hideSpinner()
  }
  await nextTick(() => {
    scrollToFirstError()
  })

  return isSuccess
}

async function completeObject() {
  await ObjectsApi.getInstance()
    .completeObject(objectId.value)
    .then((response) => {
      hideSpinner()
      if (response.data != null) {
        modalOpen.value = true
      }
    })
    .catch((e) => console.log(e))
}

async function hideModal() {
  return Promise.resolve(modalOpen.value = false)
    .then(() => router.push({path: objectShowLink()}))
}

function getAttributeValue(objectData) {
  let attrType = objectData.attribute.attributeType

  if (objectData.attribute.list) {
    return objectData.properties.map(property => parsePropertyValue(attrType, property.value))
  }
  let property = objectData.properties[0]
  return parsePropertyValue(attrType, property.value)
}

function parsePropertyValue(attrType, value) {
  if ((attrType === AttributeType.DATE || attrType === AttributeType.DATE_TIME) && value != null) {
    value = Number(new Date(value).getTime())
  }
  return value
}

function getAutoFillData(item, property) {
  if (!item.autoFillData) {
    return null
  }

  let autoFillData = item.autoFillData.find(data => !data.propertyIndex || data.propertyIndex === property.index)
  if (autoFillData === undefined) {
    return null
  }
  return autoFillData
}

function objectShowLink() {
  let link = '/object/show/' + objectId.value
  if (isEmbedUrl.value) {
    link += '?displayType=embed'
  }
  return link
}

function scrollToFirstError() {
  nextTick(() => {
    const el = rootEl.value?.getElementsByClassName('mcf-error')[0]
    if (el) {
      el.scrollIntoView({behavior: 'smooth'})
    }
  })
}

function addProperty(item) {
  let properties = item.properties
  let newProperty = new Property()

  if (properties.length > 0) {
    newProperty.index = Math.max(...properties.map(prop => prop.index)) + 1
  } else {
    newProperty.index = 0
  }
  properties[properties.length] = newProperty
}

function removeProperty(item, index) {
  item.properties.splice(index, 1);
}

function isShowItem(item) {
  return item.attribute.currentDisplaySetting.showInForm
}
</script>

<style scoped lang="scss">
.error {
  color: red;
}

.mcf-button-with-tooltip {
  position: relative;
  z-index: 1;
  @include flexbox;
  @include flex-direction(column);
  @include mq(tablet-wide, min) {
    @include flex-direction(row);
  }

  .mcf-button-fill {
    @include mq(tablet-wide, max) {
      & + .mcf-field-alert {
        opacity: 1;
        visibility: visible;
      }
    }
    @include mq(tablet-wide, min) {
      &:hover {
        & + .mcf-field-alert {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.mcf-field-alert {
  opacity: 0;
  visibility: hidden;
  @include flexbox;
  @include align-self(baseline);
  @include align-items(center);
  color: $static-color;
  min-width: 100%;
  border-radius: 8px;
  border: 1px solid $no-completed-color;
  background: #fff5df;
  font-size: 9px;
  padding: 7px 5px;
  @include mq(tablet-wide, min) {
    position: absolute;
    bottom: 110%;
    left: 50%;
    @include transform(translate(-50%, 0));
    max-width: 180px;
    font-size: 10px;
    padding: 7px 10px;
    @include transition($main-transition);
  }

  .mcf-icon {
    font-size: 12px;
    color: $no-completed-color;
    margin: 0 7px 0 5px;
    @include mq(tablet-wide, min) {
      margin: 0 10px 0 5px;
    }
  }
}
</style>
